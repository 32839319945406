import React, { useState, useContext } from 'react';
import { AppContext } from '~context';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Button, TextField, Grid, Stack } from '@mui/material';
import ReactFileReader from 'react-file-reader';
import Papa from 'papaparse';
import IconButton from '@mui/material/IconButton';
import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import OutlinedInput from '@mui/material/OutlinedInput';
import { doc, setDoc, addDoc, collection, Timestamp } from 'firebase/firestore';
import { db, storage } from '../../../firebase';
import translate from '~translate';
import { useTranslate }from '~translate-to-string';

const SearchBar = ({ getEvents, events, setSearchEvents, setSearchActive, search, setSearch }) => {
  const { setEventFormOpen, setFlag, locale, setCheckInCount } = useContext(AppContext);
  // const [search, setSearch] = useState("")

  const classes = useStyles();
  const theme = useTheme();

  const onSearchPress = () => {
    if(search.length) {
      setSearch("")
      setSearchEvents([])
      setSearchActive(false)
    }
  }

  const handleSearch = (value) => {
    setSearch(value)
    setSearchActive(true)
    const matchingEvents = events.filter((e) => e.name.toLowerCase().includes(value.toLowerCase()) || e.school.toLowerCase().includes(value.toLowerCase()))

    return setSearchEvents(matchingEvents)
  };

  const addNewEvent = async () => {
    await Promise.all([setFlag(false), setEventFormOpen(true), setCheckInCount(0)]);
  };

  const handleFiles = (files) => {
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        await Promise.all(
          results.data.map(
            async (event) => {
              event.date = Timestamp.fromDate(new Date(event.date));
              event.virtual = (event.virtual === "true");
              return await addDoc(collection(db, 'events'), event)
            }
          )
        );
        getEvents();
      },
    });
  };


  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          xs={6}
          className={classes.buttonContainer}
          sx={{ marginLeft: 5 }}
        >
          <Grid 
              container 
              spacing={{ xs: 2, md: 2 }} 
              gap={{xs:.6}}
              marginTop={{xs:0}}>
              
            <Button
              variant='outlined'
              aria-label='add new event'
              className={classes.buttonLeft}
              onClick={addNewEvent}
              style={{
                marginRight: '15px',
                borderRadius: '0',
                border: '1px solid #AF1608',
                opacity: 1,
                fontSize: '10px',
              }}
            >
              {translate('addNewEventUpperCase')}
            </Button>
            <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
              <Button
                variant='outlined'
                aria-label='upload csv'
                className={classes.buttonRight}
                style={{
                  marginRight: '15px',
                  borderRadius: '0',
                  border: '1px solid #3B3C86',
                  color: '#3B3C86',
                  opacity: 1,
                  fontSize: '10px',
                }}
              >
                {translate('uploadCSVUpperCase')}
              </Button>
            </ReactFileReader>
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Stack direction='row' spacing={0}>
            <OutlinedInput
                  id="input-with-icon-adornment"
                  placeholder={useTranslate('searchEvents')}
                  variant="outlined" 
                  onChange={(e) => handleSearch(e.target.value)}
                  value={search}
                  className={classes.searchField}
                  color='secondary'
                  style={{height:30, width:260}}
                  startAdornment={
                    <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={onSearchPress}>
                      {!search.length ?
                      <SearchIcon/>
                      :
                      <Clear />
                      }
                    </IconButton>
                  }
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchBar;

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: '100%',
    padding: '15px',
    paddingTop: 0,
  },
  buttonLeft: {
    width: '115px',
    height: '40px',
    color: '#AF1608',
  },
  buttonRight: {
    width: '115px',
    height: '40px',
  },
  searchField: {
    backgroundColor: "#f7f7f7",
    width: 300,
    borderRadius: "5px 5px 0px 0px",
  },
  searchButton: {
  },
}));

