import { EventCalendar } from '~components/events/event-calendar';

const Calendar = () => {

  return (
    <div>
      <EventCalendar/>
    </div>
  );
};

export default Calendar;
