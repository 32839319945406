import { db } from '../../firebase';
import { collection, onSnapshot, getDocs, doc } from "firebase/firestore";

const getCreditsRedeemed = async(parentId) => {
    const collectionRef = collection(db, `parents/${parentId}/redemptions`);
    const req = await getDocs(collectionRef);
    const redemptions = [];
    if(req.docs.length>0){
      req.docs.map((credit, idx) => {
        const row = {id: credit.id, ...credit.data()};
        redemptions.push(row);
      })
    }
    return redemptions;
}

  export {getCreditsRedeemed}