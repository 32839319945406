import { useContext } from 'react';
import { Route, Routes } from 'react-router';
import { AppContext } from '~context';
import { AuthProvider } from './Auth';
import { I18nProvider } from './i18n';
import PrivateRoute from './PrivateRoute';
import { ForgotPasswordForm } from './routes/forgot-password';
import { Home } from './routes/home';
import { Login } from './routes/login';

export const Router = () => {
  const { locale } = useContext(AppContext);

  return (
    <I18nProvider locale={locale}>
      <AuthProvider>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route exact path='/forgot-Password' element={<ForgotPasswordForm />} />
          <Route
            exact
            path='/home/*'
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </I18nProvider>
  );
};
