const translateToString = (string, language) => {
    switch(string) {
        case "Required":
          if(language === 'en-us') {
              return "Required"
          } else {
              return "Requierdo"
          }
        case "Select Date":
          if(language === 'en-us') {
              return "Select Date"
          } else {
              return "Seleccione Fecha"
          }
        default:
          return ""
    } 
}

export {
    translateToString,
}