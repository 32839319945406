import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs } from 'firebase/firestore';
import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { db } from '../firebase';
import { LOCALES } from '../i18n';
import { subscribeEvents, subscribeParents } from '../services/firebase';
export const AppContext = createContext({});
 
const AppContextProvider = (props) => {
  const [cookies, setCookie] = useCookies(["language"]);
  const [email, setEmail] = useState('');
  const [lastVisible, setLastVisible] = useState();
  const [password, setPassword] = useState('');
  const [eventFormOpen, setEventFormOpen] = useState(false);
  const [announcementFormOpen, setAnnouncementFormOpen] = useState(false);
  const [rewardFormOpen, setRewardFormOpen] = useState(false);
  const [resourceFormOpen, setResourceFormOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileOptionsOpen, setMobileOptionsOpen] = useState(false);
  const [locale, setLocale] = useState(cookies.language ? cookies.language : LOCALES.ENGLISH);
  const [language, setLanguage] = useState(cookies.language ? cookies.language : 'en-us');
  const [eventFormData, setEventFormData] = useState({});
  const [eventData, setEventData] = useState({});
  const [announcementData, setAnnouncementData] = useState({});
  const [rewardData, setRewardData] = useState({});
  const [rewardFormData, setRewardFormData] = useState({});
  const [resourcesFormData, setResourcesFormData] = useState({});
  const [resourceData, setResourceData] = useState({});
  const [events, setEvents] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [parents, setParents] = useState([]);
  const [checkInCount, setCheckInCount] = useState(0);
  const [loading, setLoading] = useState(0);
 
  
  const [flag, setFlag] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [activeFilter, setActiveFilter] = useState('All Events');
  const [schools, setSchools] = useState([]);
 
  const auth = getAuth();
 
  const navList = [
    { name: 'dashboard', link: '/home/dashboard' },
    { name: 'events', link: '/home/events' },
    { name: 'announcements', link: '/home/announcements' },
    { name: 'rewards', link: '/home/rewards' },
    { name: 'resources', link: '/home/resources' },
    { name: 'calendar', link: '/home/calendar' },
    { name: 'parents', link: '/home/parents' },
    { name: 'delete database', link: '/home/delete-database' },
  ];
 
  const getSchools = async () => {
    try {
      const queryRef = collection(db, 'schools');
      const schoolsSnap = await getDocs(queryRef);
      const schoolsData = [];
      schoolsSnap.forEach((doc) => {
        const school = {
          id: doc.id ?? null,
          name: doc.data().name ?? '',
          type: doc.data().type ?? '',
        };
        schoolsData.push(school);
      });
      schoolsData.sort((a, b) => a.name.localeCompare(b.name))
      setSchools(schoolsData);
    } catch (error) {
      console.log('ERROR!!', error);
    }
  };
 
  const eventCategory = ['Academic', 'Athletic', 'District', 'Community', 'Fine Arts', 'General', 'Sports'];
 
  useEffect(() => {
    // console.log("EVENT DATA", eventData)
    getSchools();
  }, [eventData]);
 
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        return getData();
      } else {
        return;
      }
    });
  }, []);
 
  const getData = () => {
    subscribeEvents(setEvents, activeFilter);
    // getParents(setParents)
    subscribeParents(parents, setParents, lastVisible, setLastVisible, setLoading);
  }
 
  const value = {
    email,
    setEmail,
    password,
    setPassword,
    locale,
    setLocale,
    mobileMenuOpen,
    setMobileMenuOpen,
    mobileOptionsOpen,
    setMobileOptionsOpen,
    navList,
    language,
    setLanguage,
    eventFormOpen,
    rewardFormOpen,
    setEventFormOpen,
    setRewardFormOpen,
    resourceFormOpen,
    setResourceFormOpen,
    eventFormData,
    setEventFormData,
    rewardFormData,
    setRewardFormData,
    resourcesFormData,
    setResourcesFormData,
    resourceData,
    setResourceData,
    eventData,
    setEventData,
    announcementData,
    setAnnouncementData,
    rewardData,
    setRewardData,
    activeFilter,
    selectedId,
    setSelectedId,
    flag,
    setFlag,
    setActiveFilter,
    schools,
    eventCategory,
    events,
    setEvents,
    parents,
    setParents,
    lastVisible,
    setLastVisible,
    subscribeParents,
    checkInCount,
    setCheckInCount,
    announcements,
    setAnnouncements,
    announcementFormOpen,
    setAnnouncementFormOpen,
    loading, setLoading
  };
 
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};
export default AppContextProvider;
 