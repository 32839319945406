import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardsContainer: {
    display: 'flex',
    marginBottom: '20%',
  },
  link: {
    margin: 0,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  card: {
    width: 300,
    height: 300,
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 7,
    marginRight: 7,
  },
  cardTitle: {
    color: theme.palette.secondary.main,
    fontSize: 26,
    margin: 0,
  },
  count: {
    color: theme.palette.primary.main,
    fontSize: 75,
    margin: 0,
  },
  whiteBackground: {
    backgroundColor: '#fff',
  },
}));

export default useStyles;
