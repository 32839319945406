import React, { useContext } from 'react';
import { AppContext } from '~context';
import ENGLISH from './messages/en-US';
import SPANISH from './messages/es-ES';

export const useTranslate = (id) => {
  const { locale } = useContext(AppContext);

  if(locale && locale === 'es-es') {
    return SPANISH[locale][id]
  } else if(locale) {
    return ENGLISH[locale][id]
  } else {
    // console.log("NO LOCALE AVAILABLE")
    return ""
  }
}
