
import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { makeStyles } from '@mui/styles';
import { AppContext } from "~context";
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import translate from '../../../i18n/translate';
import { theme } from '../../../utils/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import { getAuth, signOut } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.neutral.main,
    },
    containerPaper: {
        height: "30vh",
        borderRadius: "0 0 0 25px",
        [theme.breakpoints.down('lg')]: {
            height: '20vh',
          },
    },
    filterTitleContainer: {
        width: "100%",
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterTitle: {
        flex: 1,
        textAlign: 'center',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: 'Poppins'
    },
    optionWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "flex-start",
        justifyContent: 'center',
        width: '90%',
        height: 100,
        // paddingRight: 30,
        paddingLeft: 20,
        // backgroundColor: 'orange',
    },
    logoutWrapper: {
        // backgroundColor: 'pink',
        minWidth: 120,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "flex-start",
        paddingTop: 35,
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.8
        }
    },
    langWrapper: {
        // backgroundColor: 'brown',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "flex-start",
    },
  }));

export const MobileOptionsMenu = () => {
    const [language, setLanguage] = useState('en-us')
    const { mobileOptionsOpen, setMobileOptionsOpen, setLocale } = useContext(AppContext);
    
    const theme = useTheme();
    const classes = useStyles();
    const auth = getAuth();
    const navigate = useNavigate();

    // const showOnDesktop = useMediaQuery(theme.breakpoints.up('xl'));
    // const showOnTabletHoriz = useMediaQuery(theme.breakpoints.up('md'));
    const showOnTabletVert = useMediaQuery(theme.breakpoints.up('sm'));
    const showOnMobile = useMediaQuery(theme.breakpoints.up('xs'));

    const handleChange = (event) => {
        setLanguage(event.target.value);
        setLocale(event.target.value);
    };

    const onSignout = () => {
        signOut(auth)
        .then(() => {
            // Sign-out successful.
            setMobileOptionsOpen(false)
            navigate("/");
        }).catch((error) => {
            // An error happened.
        });
    }

    return (
            <SwipeableDrawer
                anchor={'right'}
                open={mobileOptionsOpen}
                disableSwipeToOpen={true}
                onClose={()=>setMobileOptionsOpen(false)}
                onOpen={()=>setMobileOptionsOpen(true)}
                disableDiscovery={true}
                classes={{paper: classes.container}}
                PaperProps={{ style: showOnTabletVert ? { height: "22vh", borderRadius: "0 0 0 25px" } : { height: "30vh", borderRadius: "0 0 0 25px" }}}
            >
                <Box
                    sx={showOnTabletVert ? { width: '35vw' } : { width: '55vw' }}
                    role="presentation"
                >
                        <div className={classes.filterTitleContainer}>
                            <Typography variant="h6" component="div" className={classes.filterTitle}>
                                {translate("options")}
                            </Typography>
                        </div>
                    <div className={classes.optionWrapper}>
                        <div className={classes.langWrapper}>
                            <LanguageIcon color="secondary" fontSize='large' />
                            <FormControl fullWidth variant="standard">
                                <Select
                                    id="language-select"
                                    value={language}
                                    //   label="Age"
                                    onChange={handleChange}
                                    disableUnderline
                                    style={{ paddingLeft: 10,  color: theme.palette.secondary.main, fontWeight: 600, fontSize: '0.9rem', width: '90%'}}
                                    sx={{color: "#fff","& .MuiSvgIcon-root": {color: theme.palette.secondary.main,}}}
                                >
                                <MenuItem value={'en-us'}>ENGLISH</MenuItem>
                                <MenuItem value={'es-es'}>ESPAÑOL</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.logoutWrapper} onClick={onSignout}>
                            <PersonIcon color="secondary" fontSize='large' />
                            <Typography variant="body2" sx={{ paddingLeft: 1, textAlign: 'left', color: theme.palette.secondary.main, fontFamily: 'Poppins', fontWeight: 600, textTransform: 'capitalize' }}>
                                {translate('logout')}
                            </Typography>
                        </div>
                    </div>
                </Box>
            </SwipeableDrawer>

    );
}