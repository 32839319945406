import React, { useEffect, useState, lazy, Suspense, useContext } from 'react';
import { makeStyles } from '@mui/styles';
//import { AppContext } from '~context';
import { Button, ButtonGroupContext, Grid, Box, Stack, Typography, CircularProgress } from '@mui/material';
import { collection, getDocs, deleteDoc, writeBatch } from 'firebase/firestore';
import { db, functions } from '../../firebase';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom'; // Assuming you are using react-router-dom for navigation
import { AppContext } from '../../Context';
import { httpsCallable } from 'firebase/functions';
import translate from '~translate';


export default function DeleteDatabase() {
  //for modal, see: https://mui.com/material-ui/react-modal/
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false); // State to track deletion process

  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSuccessClose = () => setSuccess(false);

  const deleteDatabaseFunction = async () => {
    setLoading(true); // Start loading state
    const deleteAllData = httpsCallable(functions, 'deleteAllData'); // functions is being called by getFunction() in firebase.js

    try {
        // Check if there are any documents to delete before calling the function
        const checkDocsToDelete = httpsCallable(functions, 'checkDocsToDelete');
        const checkResponse = await checkDocsToDelete();
        
        if (checkResponse.data.docsExist) {
            await deleteAllData(); // Call Firebase Cloud Function

            // Check if all documents were successfully deleted
            const checkAfterDelete = await checkDocsToDelete();
            if (!checkAfterDelete.data.docsExist) {
                setOpen(false);
                setLoading(false); // End loading state
                setSuccess(true);
            } else {
                // Indicate partial success
                console.warn('Some documents could not be deleted. Please check the logs for details.');
                setOpen(false);
                setLoading(false); // End loading state
                setSuccess(true);
            }
        } else {
            alert('No documents to delete.');
            setOpen(false);
            setLoading(false); // End loading state
            setSuccess(true);
        }
    } catch (error) {
        console.error('Error deleting data:', error);
        setOpen(false);
        setLoading(false); // End loading state in case of error
        setSuccess(true); // Indicate failure
    }
};


  const LoadingModal = ({ loading }) => {
    return (
      <Modal
        open={loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2" align="center">
            {translate('deletingdata')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <CircularProgress />
          </Box>
        </Box>
      </Modal>
    );
  };

  const ModalToDelete = ({ open, handleClose }) => {
    //console.log("Modal successful")

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            {translate('Are you sure!')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Button variant="outlined" sx={{ m: 2 }} onClick={handleClose}>
              NO
            </Button>
            <Button variant="outlined" sx={{ m: 2 }} onClick={deleteDatabaseFunction}>
              {translate("yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }

  const NotifyMessage = ({ success, handleSuccessClose }) => {
    //console.log("task is complete")

    return (
      <Modal
        open={success}
        onClose={handleSuccessClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center" >
            {translate('You have successfully deleted below collections:')} <br />
            - announcements <br />
            - emailQueue <br />
            - events <br />
            - parents <br />
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <Button variant="outlined" sx={{ m: 2 }} onClick={() => navigate('/home/dashboard')}>
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  return (
    <div style={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          p: 12,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
        }}
      >
        <p style={{ color: 'red', fontWeight: 'bold' }}>
          {translate("deletewarning")}
        </p>
        <Typography>
          {translate("notewarning")}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            p: 3,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Button
            variant="outlined"
            sx={{ m: 2 }}
            onClick={() => navigate('/home/dashboard')}>
            {translate("CANCEL")}
          </Button>
          <Button
            variant="contained"
            sx={{ m: 2 }}
            onClick={handleOpen}
            startIcon={<DeleteIcon />}
          >
            {translate("delete")}
          </Button>
        </Box>
        <ModalToDelete open={open} handleClose={handleClose} />
        <NotifyMessage success={success} handleSuccessClose={handleSuccessClose} />
        <LoadingModal loading={loading} />
      </Box>
    </div>
  );
}
