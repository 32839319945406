import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import CreditHistoryView from '../../components/parents/credit-history';
import { AppContext } from '../../Context';

const useStyles = makeStyles(() => ({
  actionBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '70px',
    minWidth: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const CreditHistory = () => {
  const classes = useStyles();
  const { parents } = useContext(AppContext);
  const { state } = useLocation();
  const [parent, setParent] = useState(state);

  useEffect(() => {
    // console.log("STATE", state)
    const parent = parents.filter(p => {
      return p.id === state.id;
    });
    setParent(parent[0]);
  }, [parents])

  return(
    <>
    
      <Grid container rowSpacing={0}>
        <Grid
          item
          xs={12}
          sx={{ width: '100%', minHeight: '500px', overflow: 'hidden' }}
          className={classes.tableContainer}
        >
          <CreditHistoryView data={parent && parent}/>
        </Grid>
      </Grid>
   
    </>

  )

}


export default CreditHistory;