import { dateToLocaleTimeString, timeToDateString } from ".";

export const validateCheckIn = async (event, userLocation, checkedIn, bypassLocation) => {
  if ((userLocation.coords.latitude === "" || userLocation.coords.longitude === "") && !bypassLocation) {
    alert('Please enable your location in system preferences and browser settings and allow access permission. Refresh page after changing settings');
    return;
  }

  const now = new Date().getTime();

  //console.log("Event data:", event.data());

  const startTime = event.data().startTime;
  const endTime = event.data().endTime;
  const eventDate = event.data().date;

  if (!startTime || !endTime || !eventDate) {
    console.error("Event fields are not properly defined");
    return alert("Event fields are not properly defined.");
  }

  const hourBeforeStartTime = new Date(timeToDateString(startTime)).getTime() - 1 * 60 * 60 * 1000;
  const hourAfterEndTime = new Date(timeToDateString(endTime)).getTime() + 1 * 60 * 60 * 1000;

  let eventDateMillis;

  if (eventDate.toMillis) {
    eventDateMillis = eventDate.toMillis();
  } else {
    return alert("Event date is not in a recognizable format.");
  }


  // DATE CHECK
  if (dateToLocaleTimeString(now) !== dateToLocaleTimeString(eventDateMillis)) {
    return alert("You can't check in, this event is not today.");
  } else {
    //console.log("PASSED DATE CHECK");
  }

  if (now < hourBeforeStartTime || now > hourAfterEndTime) {
    if (now < hourBeforeStartTime) {
      return alert("You can't check in yet. Please wait until at least an hour before for the event.");
    }
    if (now > hourAfterEndTime) {
      return alert("You can no longer check in. The event has ended.");
    }
  }

  if (checkedIn) {
    return alert('You are already checked in for this event.');
  }

  return true;
};
