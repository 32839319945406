import { getCreditHistory, getCreditRequests, getCreditsRedeemed, getStudents} from "./index";

const resolveParentData = (array, setParents, parents) => {
  function getSchools(students) {
    const schools = [];
    students.forEach(student => {
      if(!schools.includes(student.school))
        schools.push(student.school);
  })
  schools.sort();
  return schools.join('\n');
}
  Promise.all(array.map(async(item) => {
    const parent = {
      id: item.id,
      _id: item.id,
      name: `${item.data().firstName} ${item.data().lastName}`,
      firstName: item.data().firstName,
      lastName: item.data().lastName,
      parentId: item.id,
      parentCell: item.data().phoneNumber,
      parentsEmail: item.data().emails.join('\n'),
      totalCredits: item.data().totalCredits ?? 0,
      creditsRedeemed: item.data().creditsRedeemed ?? 0,
      availableCredits: (item.data().totalCredits ?? 0) - (item.data().creditsRedeemed ?? 0),
      redeemedHistory: await getCreditsRedeemed(item.id),
      creditHistory: await getCreditHistory(item.id),
      creditRequest: await getCreditRequests(item.id),
      students: await getStudents(item.id),
    };
    // console.log('parent', parent.parentsEmail);
    parent.schools = getSchools(parent.students);
    return parent
  }))
  .then((res) => {
    if (parents) setParents([...parents, ...res]);
    else setParents(res);
  })
}

export {resolveParentData}