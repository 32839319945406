import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { AppContext } from '~context';
// import ParentsTable from '../../components/parents/parents-table';

const TableComponent = lazy(() =>
  import('../../components/parents/parents-table')
);

const useStyles = makeStyles(() => ({
  actionBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '70px',
    minWidth: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Parents = () => {
  const { parents } = useContext(AppContext);
  const classes = useStyles();
  const [data, setData] = React.useState(parents);

  useEffect(() => {
    // console.log("parents changed", parents)
    setData(parents);
  }, [parents]);

  return (
    <>
      <Grid container rowSpacing={0}>
        <Grid
          item
          xs={12}
          sx={{ width: '100%', minHeight: '500px', overflow: 'hidden' }}
          className={classes.tableContainer}
        >
          <Suspense fallback={<div>Loading..........</div>}>
            <TableComponent data={ data } />
          </Suspense>
          {/* {
            parents.length ?
            <ParentsTable data={parents}/>
            :
            <div>loading....</div>
          } */}
        </Grid>
      </Grid>
    </>
  );
};

export default Parents;
