import React, { useState, useEffect, useContext } from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./eventCalendar.css";

export const MarkedDate = ({date, events, view}) => {
    const [eventsCount, setEventsCount] = useState("")

    const theme = useTheme();

    const showMobile = useMediaQuery(theme.breakpoints.down('md'));

    const checkForEvents = () => {
        //CHECK WHICH EVENTS MATCH THE DATE OF THIS COMPONENT, SAVE THAT NUMBER TO eventsCount
        //CAN MATCH TO A SPECIFIC DATE OR A RANGE OF DATES, DEPENDING ON WHETHER THE USER
        //IS VIEWING THE MONTHLY OR YEARLY CALENDAR.
        let dateEvent
        
        if(view === "month") {
            dateEvent = events.filter((event) =>  Date.parse(formatDate(event.dateString)) === Date.parse(formatDate(date)))
        } else {
            dateEvent = events.filter((event) =>  new Date(event.dateString).getMonth() === date.getMonth())
        }
        setEventsCount(dateEvent.length)
    };

    const formatDate = (date) => {
        const options = { year: "numeric", month: "numeric", day: "numeric"};
        const dateToConvert = new Date(date);
        return dateToConvert.toLocaleDateString("en-US", options);
    };

    useEffect(() => {
        checkForEvents()
    },[events])

    return (
      <div className="calendarMarkedDateContainer">
          <div className="calendarMarkedDate">
                <div style={{borderRadius: 50, backgroundColor: "#B21414", width: 30, height: 30, margin: 5, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p style={{color: "#fff", fontSize: 18, fontWeight: "bold"}}>{eventsCount}</p>
                </div>
          </div>
      </div>
    );
  };