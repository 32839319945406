import React from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    dividerLine: {
      height: '100%',
      width: 1,
      backgroundColor: theme.palette.neutral.secondary
    },
    lineContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 2,
        paddingLeft: 2
    }
  }));

export const VerticalDividerLine = () => {
    const classes = useStyles();

  return (
    <div className={classes.lineContainer}>
        <div className={classes.dividerLine}></div>
    </div>
  );
};
