import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import styles from './styles';
import Dashboard from '../dashboard/index';
import Calendar from '../calendar/index';
import Events from '../events/index';
import Announcements from '../announcements/index';
import Parents from '../parents/index';
import Resources from '../resources/index';
import Rewards from '../rewards/index';
import CreditHistory from '../credit-history/index';
import { DesktopSideNav } from '../../components/navigation/desktopSideNav';
import { Header } from '../../components/header';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import  DeleteDatabase  from '../delete-database';
import ManualCheckIn from '../manual-check-in/index';
 
const useStyles = makeStyles(styles);
 
export const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
 
  const showMobile = useMediaQuery(theme.breakpoints.down('md'));
 
  return (
    <div className={classes.container}>
      {!showMobile && (
        <div>
          <DesktopSideNav />
        </div>
      )}
      <div style={{ width: '100%' }}>
        <Header />
        <Routes>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/events' element={<Events />} />
          <Route path='/announcements' element={<Announcements />} />
          <Route path='/rewards' element={<Rewards />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/parents' element={<Parents />} />
          <Route path='/credit-history' element={<CreditHistory />} />
          <Route path='/delete-database' element={<DeleteDatabase />} />
          <Route path='/manual-check-in' element={<ManualCheckIn />} />
        </Routes>
      </div>
    </div>
  );
};
 