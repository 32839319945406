import { db } from "~firebase";
import { collection, onSnapshot } from "firebase/firestore";

export const subscribeStudents = (parentId, setAllStudents) => {
  const colorsArray = ["#448F99", "#9114b8", "#73bbff", "#a1e6b3", "#e6e4a1", "#FFBA49", "#e6c4a1", "#b894d4", "#d494cf", "#e8b4ac", "#A36500", "#accae8", "#9496d4", "#d993b6", "#9de0a9", "#d993a1", "#826dc2", "#d99393", "#f58484", "#9ed5e6", "#9aa7ab"]
  try {
    const creditHistoryRef = collection(db, `parents/${parentId}/students`)
    
    onSnapshot(creditHistoryRef, (snapshot) => {
      //console.log("SUBSCRIBE students docs: ", snapshot.docs)
        if(snapshot.docs.length > 0){
          const students = [];
          snapshot.docs.forEach((student, idx) => {
            const studentObject = {
              studentId: student.data().studentId,
              studentFirstName: student.data().firstName,
              studentLastName: student.data().lastName,
              studentSchool: student.data().school,
              studentCredits: student.data().studentCredits ?? 0,
              gradeLevel: student.data().gradeLevel,
              color: colorsArray[idx],
            }
            students.push(studentObject);
          })
         // console.log("STUDENTS", students);
          setAllStudents(students);
        }
      })
  } catch (error) {
    //console.log("ERROR SUBSCRIBING TO STUDENTS COLLECTION", error);
  }
};