import React from 'react'
import { PRIMARY, SECONDARY } from '../../utils/constraints';

export default function Ticker({width="100%", countColor=SECONDARY, textColor=PRIMARY, countSize=90, textSize=20, count=0, text="placeholder"}) {
    
    const styles = {
        container: {
            width: width,
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        count: {
            color: countColor,
            fontSize: countSize,
            fontWeight: "bold",
            margin:0,
            lineHeight: 0.6,
            marginBottom: 25,
            marginTop: 10,
        },
        text:{
            color: textColor,
            fontSize: textSize,
            fontWeight: "bold",
            textTransform: "uppercase",
            margin: 0,
        }
    }
    
    return (
        <div style={styles.container}>
            <p style={styles.count}>{count}</p>
            <p style={styles.text}>{text}</p>
        </div>
    )
}
