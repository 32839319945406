import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppContext } from '~context';
import translate from '../../i18n/translate';
import {
  getAuth, sendPasswordResetEmail
} from "firebase/auth";
import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import mainLogo from '../../res/logo.png';
import styles from './styles';
const useStyles = makeStyles(styles);

export const ForgotPasswordForm = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const {setLocale} = useContext(
    AppContext
  );

  const classes = useStyles();

  const onResetPassword = () => {
    sendPasswordResetEmail(auth, email)
    .then(() => {
      alert(`Password reset email sent to ${email}. If you cannot find it, please also check your spam folder.`);
      navigate("/");
    })
    .catch((error) => {
      console.log(error);
      alert('There was an error with your password reset request. Please check that your email address is correct and try again.');
    });
  }

  return (
    <div style={styles.mainContainer}>
      <Button onClick={() => setLocale('en-us')}>{translate('english')}</Button>
      <Button onClick={() => setLocale('es-es')}>{translate('spanish')}</Button>
      <img src={mainLogo} className={classes.logo} alt='fireSpot' />
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '30ch' },
        }}
        noValidate
        autoComplete="off"
        style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20}}
      >
        <Typography variant="h6" gutterBottom component="div" color="secondary" style={{display: "flex", justifyContent: "center"}}>
            {translate('resetpassword')}
        </Typography>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <Typography variant="subtitle1" component="div" color="secondary" textAlign='center' style={{display: "flex", marginBottom: 20, justifyContent: "center"}}>
            {translate('resetdescription')}
        </Typography>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}> 
                <TextField
                    fullWidth 
                    id="outlined-basic" 
                    label={translate('emailaddress')} 
                    variant="outlined" 
                    color="secondary"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{marginBottom: 20}}
                />
            </div>
            <Button style={{marginBottom: 20}} variant="contained" onClick={onResetPassword}>{translate('submit')}</Button>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
              <Link href="/" underline='none' color='secondary'>{translate('returntologin')}</Link>
            </div>
        </div>
      </Box>
    </div>
  );
};