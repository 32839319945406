import { db } from '../../firebase';
import { collection, getDocs } from "firebase/firestore";

const getStudents = async(parentId) => {
    const collectionRef = collection(db, `parents/${parentId}/students`);
    const req = await getDocs(collectionRef);
    const students = [];
    if(req.docs.length>0){
      req.docs.map((student) => {
        const row = {id: student.id, ...student.data()};
        students.push(row);
        return row;
      })
    }
    return students;
}

  export {getStudents}