import React, { useState, useContext } from 'react';
import { Button, Box } from '@mui/material';
import { AppContext } from '~context'; // Adjust the path according to your project structure
import { getCheckInStatus, getStudentsOnEvent, pickRandomStudent, validateCheckIn } from "../../../../functions";
import { getCreditHistory } from "../../../../functions/queries";
import { updateParentCredits, subscribeStudents, updateStudentCredits } from "../../../../services/firebase";
import { useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl';
import { DataGrid } from '@mui/x-data-grid';
import SearchBar from './search-bar';

const ManualCheckInComponent = ({ data }) => {
  const {
    eventData,
    selectedId,
  } = useContext(AppContext);
  
  const [allStudents, setAllStudents] = useState([]);
  const [eventStudents, setEventStudents] = useState([]);
  const [randomStudent, setRandomStudent] = useState(null);
  const [address, setAddress] = useState('');
  const [checkedIn, setCheckedIn] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [ref, setRef] = useState();
  const [rows, setRows] = useState(data);
  const translateTostring = useIntl();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [creditHistory, setCreditHistory] = useState([]);
  const [checkInStatus, setCheckInStatus] = useState({});

  React.useEffect(() => {
    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
    let filteredRows = data.filter((row) => {
      return searchRegex.test(row.firstName?.toString()) || searchRegex.test(row.lastName?.toString());
    })
    if (eventData.data().school !== 'Select All')
      filteredRows = filteredRows.filter(row => row.schools.includes(eventData.data().school));
    setRows(filteredRows);
    setPageNumber(0);
  }, [data, searchText]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) => {
      return searchRegex.test(row.firstName?.toString()) || searchRegex.test(row.lastName?.toString());
    }).filter(row => row.schools.includes(eventData.data().school));
    setRows(filteredRows);
  };

  const onCheckIn = async (param) => {
    const userLocation = {
      coords: {
        latitude: "",
        longitude: ""
      }
    };
    //console.log("Begin check in process");
    
    const creditHistoryData = await getCreditHistory(param.row.id);
    setCreditHistory(creditHistoryData);
    const checkInStatus = await getCheckInStatus(creditHistoryData, selectedId);
    const validCheckIn = await validateCheckIn(eventData, userLocation, checkInStatus, true);
    
    await subscribeStudents(param.row.id, setAllStudents);
    //console.log("parent id: ", param.row.id);
    //console.log("All students after subscription:", allStudents);
    
    const studentsOnEvent = getStudentsOnEvent(eventData, allStudents);
    //console.log("Students on event:", studentsOnEvent);
    
    const randomPickedStudent = pickRandomStudent(studentsOnEvent);
    //console.log("Random picked student:", randomPickedStudent);
    
    setEventStudents(studentsOnEvent);
    setRandomStudent(randomPickedStudent);

    if (validCheckIn) {
      await updateParentCredits(param.row.id, eventData, studentsOnEvent);
      //await updateStudentCredits(param.row.id, randomStudent.studentId, eventData.data().reward);
      setCheckInStatus(prev => ({ ...prev, [param.row.id]: true })); // Update the check-in status
    } else {
      setCheckInStatus(prev => ({ ...prev, [param.row.id]: false })); // Update the check-in status
    }
  };

  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  const handleClick = (param) => {
    onCheckIn(param);
  };

  const columns = [
    {
      field: 'firstName',
      headerName: translateTostring.formatMessage({ id: 'FIRST NAME' }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      width: 100,
    },
    {
      field: 'lastName',
      headerName: translateTostring.formatMessage({ id: 'LAST NAME' }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      width: 100,
    },
    {
      field: 'parentsEmail',
      headerName: translateTostring.formatMessage({ id: 'emailCap' }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      width: 200,
      renderCell: (params) => {
        const emails = params.row.parentsEmail.split('\n');
        return (
          <div key={params.row._id} style={{ width: 200 }}>
            {emails.map((email, index) => {
              return <p key={index}>{email}</p>;
            })}
          </div>
        );
      }
    },
    {
      field: 'schools',
      headerName: translateTostring.formatMessage({ id: 'SCHOOLCAP' }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      width: 200,
      renderCell: (params) => {
        const schools = params.row.schools.split('\n');
        return (
          <div key={params.row._id} style={{ width: 200, textAlign: 'start' }}>
            {schools.map((school, index) => {
              return <p key={index}>{school}</p>;
            })}
          </div>
        );
      }
    },
    {
      field: 'totalCredits',
      headerName: translateTostring.formatMessage({ id: 'totalCreditsUpperCase' }),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      width: 50,
    },
    {
      hide: true,
      field: 'creditsRedeemed',
      headerName: translateTostring.formatMessage({ id: "REDEEMED" }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      width: 110,
    },
    {
      field: 'availableCredits',
      headerName: translateTostring.formatMessage({ id: "AVAILABLE" }),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
      width: 110,
      hide: true,
    },
    {
      width: 150,
      field: 'checkInStatus',
      headerName: '',
      renderCell: (cellValues) => {
        const isCheckedIn = checkInStatus[cellValues.row.id];
        return (
          <>
            {isCheckedIn ? (
              <span style={{ color: 'green' }}>Check In Successful</span>
            ) : (
              <>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={() => {
                    handleClick(cellValues);
                  }}
                >
                  Check In
                </Button>
              </>
            )}
          </>
        );
      },
      headerAlign: 'right',
      align: 'right',
      sortable: false,
    }
  ];

  return (
    <>
      <Box
        sx={{
          height: '90vh',
          width: '100%',
          paddingLeft: 1,
          paddingRight: 1,
          boxSizing: 'border-box',
          overflowX: 'hidden',
          maxWidth: {
            xs: '100%',    // small screens
            sm: '90%',     // small-medium screens
            md: '80%',     // medium screens
            lg: '90%',     // large screens
            xl: '90%'      // extra-large screens
          },
          margin: '0 auto'
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={rows?.length > 0 ? rows : data ? data : []}
          columns={columns}
          pageSize={50}
          page={pageNumber}
          rowsPerPageOptions={[3]}
          sx={{ border: 'none', color: '#3B3C86' }}
          pagination
          onPageChange={(pageNo) => setPageNumber(pageNo)}
          slots={{
            toolbar: SearchBar
          }}
          slotProps={{
            toolbar: {
              value: searchText,
              data: data,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
        />
      </Box>
    </>
  );
};

export default ManualCheckInComponent;
