import React, { useContext, useEffect} from 'react';
import { AppContext } from '~context';
import { getEvents } from "~functions/queries";
import ManualCheckInComponent from '../../components/events/events-form/manual-checkin/index.js';

const ManualCheckIn = () => {
  const { activeFilter, setEvents, parents } = useContext(AppContext);
  const [data, setData] = React.useState(parents);

  const getCheckCount = async (id) => {
    let count = 0;
    parents.forEach(parent => {
      parent.creditHistory.forEach(item => {
        if(item.id === id) {
          count += 1;
        }
      })
    });
    return count;
  }

  const updateEvents = async () => {
    setEvents([]);
    const tableRows = await getEvents(activeFilter);
    await Promise.all(tableRows.map(async (event) => {
      event.checkInCount = await getCheckCount(event.id);
      return;
    }))
    setEvents(tableRows);
  };

  useEffect(() => {
    updateEvents();
  }, [activeFilter]);

  useEffect(() => {
    // console.log("parents changed", parents)
    setData(parents);
  }, [parents]);

  return (
    <>
    <ManualCheckInComponent data = {data}/>
    </>
  );
};

export default ManualCheckIn;
