import React, { useContext } from 'react';
import { AppContext } from '~context';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import ReactFileReader from 'react-file-reader';
import { Button, TextField, Grid, Stack } from '@mui/material';
import Papa from 'papaparse';
import { doc, setDoc, addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../../../firebase';
import translate from '~translate';
const ActionBar = ({getRewards}) => {
  const { setRewardFormOpen, setFlag } = useContext(AppContext);

  const classes = useStyles();
  

  const addReward = async () => {
    await setFlag(false);
    await setRewardFormOpen(true);
    
  };

  const handleFiles = (files) => {
    
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async(results) => {
        await Promise.all(results.data.map( async(reward) => await addDoc(collection(db, 'rewards'), reward)))
        getRewards();
      }}
    )
  }

  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          xs={6}
          className={classes.buttonContainer}
          sx={{ marginLeft: 5 }}
        >
          <Stack direction='row' spacing={0.5}>
            <Button
              variant='outlined'
              aria-label='add new reward'
              className={classes.buttonLeft}
              onClick={addReward}
              style={{
                marginRight: '15px',
                borderRadius: '0',
                border: '1px solid #AF1608',
                opacity: 1,
                fontSize: '10px',
                width: '150px',
                textTransform: 'uppercase'
              }}
            >
              {translate('addNewReward')}
            </Button>
            <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
              <Button
                variant='outlined'
                aria-label='upload csv'
                className={classes.buttonRight}
                style={{
                  marginRight: '15px',
                  borderRadius: '0',
                  border: '1px solid #3B3C86',
                  color: '#3B3C86',
                  opacity: 1,
                  fontSize: '10px',
                }}
              >
                {translate('uploadCSV')}
              </Button>
            </ReactFileReader>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ActionBar;

const useStyles = makeStyles(() => ({
  buttonContainer: {
    width: '100%',
    padding: '15px',
    paddingTop: 0,
  },
  buttonLeft: {
    width: '115px',
    height: '40px',
    color: '#AF1608',
  },
  buttonRight: {
    width: '115px',
    height: '40px',
  },
  searchContainer: {
    display: 'flex',
  },
}));
