import { Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import translate from '~translate';
import './table.css';
import { useTranslate } from '../../../../i18n/translate-to-string';

const RequestsTable = ({ data, viewRequest }) => {
  const viewRequestButton = useTranslate("ViewRequest")

  const columns = [
    {
      field: 'name',
      headerName: translate('name'),
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      sortable: true,
    },
    {
        field: 'description',
        headerName: translate('description'),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        sortable: true,
    },
    {
      field: 'date',
      headerName: translate('date'),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
    },
    {
      width: 200,
      renderCell: (cellValues) => {
        return (
            <>
            {cellValues.row.approved ?
            <div>
            <p>Approved {'\n'} for {cellValues.row.credits} credits</p>
            {/* <p>for {cellValues.row.credits} credits</p> */}
            </div>
            :
            <Button
            color="secondary"
            variant='outlined'
              onClick={() => {
                handleClick(cellValues);
              }}
            >{viewRequestButton}</Button>
            }
            </>
        );
      },
      headerAlign: 'right',
      align: 'right',
      sortable: false,
   } 
  ];
  
  const handleClick = (param) => {
      // console.log('testyy', param);
      viewRequest(param.row);
    };

  return (
    <>
      <div
        style={{
          height: 600,
          width: '100%',
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <DataGrid
          rows={ data }
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          rowHeight={85}
          sx={{ border: 'none', color: '#3B3C86' }}
          disableColumnMenu={true}
        />
      </div>
    </>
  );
};

export default RequestsTable;
