import { TabContext, TabPanel } from '@mui/lab';
import {
  Box, Stack,
  TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import translate from '~translate';
import { db } from '../../../firebase';
import { DividerLine, VerticalDividerLine } from '../../dividerLine';
import Ticker from '../../ticker';
import {DataTable, RedeemedDataTable } from './data-table';
import RequestsTable from './data-table/request-table';
import './form.css';
import { useStyles } from './style';
import { translateToString } from '../../../functions/other';


const CreditHistoryView = ({ data }) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState('0');
    const [open, setOpen] =  useState(false);

    const {
      control,
      register,
      formState: { errors },
      handleSubmit,
      setValue,
      reset,
    } = useForm({
      defaultValues: {}
    });

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const viewRequest = (requestData) => {
      // console.log('date', requestData)
      setValue('name', requestData.name);
      setValue('description', requestData.description);
      setValue('points', 0);
      setValue('requestId', requestData.id)
      // setValue('date', Timestamp.fromDate(requestData.date));
      setValue('date', new Date(requestData.date));
      setOpen(true);
    }

    const onSubmit = async(formData) => {
        try {
            formData.points = Number(formData.points);
            formData.date = formData.date.toDateString();
            const updateRef = doc(db, 'parents', data.parentId);
            const colRef = collection(updateRef, 'credits-history');
            await addDoc(colRef, formData);
            await setDoc(updateRef, {totalCredits: parseInt(data.totalCredits) + parseInt(formData.credits)}, {merge: true});
            const refCol = doc(db, 'parents', data.parentId);
            const refDoc = doc(refCol, 'request-history', formData.requestId);
            await setDoc(refDoc, {approved: true, credits: formData.credits}, {merge: true});
          setOpen(false);
          reset();
          // setParents([])
          // await getParents(setParents);
        } catch (e) {
          console.log('error writing to db', e);
        }
      };

    const firebaseTimeStampToDate = (dateTime) => {
      const fireBaseTime = new Date(
        dateTime.seconds * 1000 + dateTime.nanoseconds / 1000000,
      );
      return fireBaseTime.toDateString();
    }

    return(
      <>
      {data &&
        <Box
        sx={{
           height: '90vh',
           width: '100%',
           paddingLeft: 2,
           paddingRight: 2,
           boxSizing: 'border-box',
           maxWidth: {
             xs: '100%',    // small screens
             sm: '90%',     // small-medium screens
             md: '80%',     // medium screens
             lg: '70%',     // large screens
             xl: '60%'      // extra-large screens
           },
           margin: '0 auto'
         }}
      >
        <div className={classes.innerView}>
        <div className={classes.creditsContainer}>
        <Ticker 
            count={parseInt(data.totalCredits - parseInt(data.creditsRedeemed))}
            text={translate("total available credits")}
            countSize={80}
            textSize={16}
        />
        <VerticalDividerLine />
        <Ticker 
            count={parseInt(data.creditsRedeemed)}
            text={translate("totalCreditsRedeemed")}
            countSize={80}
            textSize={16}
        />
        </div>
        <div className={classes.innerView}>
        <DividerLine />
        
        <TabContext value={selectedTab} >
        

            <Tabs 
                value={selectedTab}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary"
                className={classes.tabsContainer}
            >
              
                <Tab label={translate('availableCredits')} value="0" />
                <Tab label={translate('redeemedCredits')} value="1" />
                <Tab label={translate('creditRequests')} value="2" />
            </Tabs>
            <TabPanel value="0"><DataTable data={data.creditHistory.length > 0 ? data.creditHistory.map(item =>  ({id: item.id, name: item.name, cost: item.credits ? parseInt(item.credits) : parseInt(item.points), date: item.date})) : []}/></TabPanel>
            <TabPanel value="1"><RedeemedDataTable data={data.redeemedHistory.length > 0 ? data.redeemedHistory.map(item =>  ({id: item.id, name: item.name, school: item.school, cost: item.cost, date: firebaseTimeStampToDate(item.date)})) : []}/></TabPanel>
            <TabPanel value="2">
                <RequestsTable 
                    data={data.creditRequest.length > 0 ? 
                    data.creditRequest.map(item =>  ({id: item.id, name: item.name, description: item.description, date: item.date, approved: item.approved ?? false, credits: item.credits ?? 0})) : []}
                    viewRequest={viewRequest}/>
            </TabPanel>
        </TabContext>
        </div>
     </div>
     <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack className={classes.formContainer}>
              <div className={classes.modelView}>
              <div>{translate('eventName')}</div>
              <TextField
                
                sx={{
                  width: '100%',
                  height: '40px',
                  //border: '1px solid #3B3C86',
                  backgroundColor: '#fff',
                  borderRadius: 1,
                  marginTop: 1,
                  marginBottom: 2
                }}
                size='small'
                id='outlined-uncontrolled'
                {...register('name', { required: true })}
                placeholder={errors.name?.type === 'required' ? translateToString("Required", language) : ""}
              />
              <div>{translate('eventDescription')}</div>
              <TextField
                sx={{
                  width: '100%',
                  height: '169px',
                  backgroundColor: '#fff',
                  //border: '1px solid #3B3C86',
                  borderRadius: 1,
                  marginTop: 1,
                  marginBottom: 2
                }}
                id='outlined-multiline-static'
                multiline
                rows={6}
                placeholder={errors.name?.type === 'required' ? translateToString("descriptionRequired", language) : ""}

                {...register('description', { required: true })}
              />
              <div>{translate('points')}</div>
              <TextField
                sx={{
                  width: '100%',
                  height: '40px',
                  backgroundColor: '#fff',
                  //border: '1px solid #3B3C86',
                  borderRadius: 1,
                  marginTop: 1,
                  marginBottom: 2
                }}
                size='small'
                id='outlined-uncontrolled'
                placeholder={errors.name?.type === 'required' ? translateToString("'Credits are required'", language) : ""}

                {...register('credits', { required: true })}
              />
              <div>
                  <div>{translate('eventDate')}</div>
                  <Box
                    component='div'
                    sx={{
                      height: '40px',
                      width: '100%',
                      border: '1px solid grey',
                      backgroundColor: '#fff',
                      borderRadius: 1,
                      marginTop: 1,
                      marginBottom: 2
                    }}
                  >
                    <Controller
                      control={control}
                      name='date'
                      render={({ field }) => (
                        <DatePicker
                          sx={{
                            height: '40px',
                            border: '1px solid grey',
                            backgroundColor: '#fff',
                            borderRadius: 1,
                            marginTop: 0,
                          }}
                          {...field}
                          placeholderText='Select date'
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                        />
                      )}
                    />{' '}
                  </Box>
                </div>
              <Button
                variant='outlined'
                color='success'
                type='submit'
                disabled={data.approved}
                sx={{
                    width: '30%',
                    height: 40,
                    border: '1px solid #3B3C86 ',
                    opacity: 1,
                    fontSize: '10px',
                    alignSelf: 'center',
                    borderRadius: 0,
                }}
              >
                {translate("Approve")}
              </Button>
              <Button
                variant='outlined'
                color='success'
                type='button'
                onClick={() => setOpen(false)}
                sx={{
                  width: '30%',
                  height: 40,
                  border: '1px solid #3B3C86 ',
                  opacity: 1,
                  fontSize: '10px',
                  alignSelf: 'center',
                  borderRadius: 0,
                  marginTop: 2,
                }}
              >
                {translate("Cancel")}
              </Button>
              </div>
            </Stack>
          </form>
      </Modal>
      
     </Box>
}
</>
    )
}

export default CreditHistoryView;