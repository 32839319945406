import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { AppContext } from '~context';
import translate from '~translate';

export default function DaySelect({setShowDayDetail, selectedDate, setSelectedDate, setEventsForDate, getData, clearSearch}) {
    const { locale, allEvents, allStudents, students } = useContext(AppContext)
    const theme = useTheme();

    const useStyles = makeStyles(() => ({
        controlContainer: {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            paddingTop: 30,
            paddingBottom: 30,
        },
        arrowHover: {
            "&:hover": {
                cursor: 'pointer',
                // opacity: "0.7",
                // backgroundColor: theme.palette.neutral.light,
                backgroundColor: "#e6e6e6",
            }
        },
        underline: {
            borderBottom: `2px solid #B21414`,
        }
        
    }));
    
    const classes = useStyles();

    const handleClose = () => {
        clearSearch();
        getData();
        setShowDayDetail(false);
    };
  
    const handleDateBack = () => {
        clearSearch();
        const yesterday = new Date(selectedDate.date)
        yesterday.setDate(yesterday.getDate() - 1)
        setEventsForDate(yesterday, "change")
    }

    const handleDateForward = () => {
        clearSearch();
        const tomorrow = new Date(selectedDate.date)
        tomorrow.setDate(tomorrow.getDate() + 1)
        setEventsForDate(tomorrow, "change")
    }
  
    const handleDate = (date, option) => {
        const dateOptions = [{ month: 'long', day: 'numeric' }, { month: 'short', day: 'numeric' }];
        let selection = new Date(date.date)
        return selection.toLocaleDateString(locale, dateOptions[option])
    };
    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center",}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "80%"}}>
                <div className={classes.arrowHover}>
                    <Typography variant={"h6"} color="primary" className={classes.underline} onClick={()=>{handleClose()}}>{translate("viewCalendarUpperCase")}</Typography>
                </div>
                <div className={classes.controlContainer}>
                    <ArrowBack className={classes.arrowHover} color="secondary" fontSize={"large"} onClick={()=>{handleDateBack()}} />
                    <Typography variant={"h4"} color="primary">{handleDate(selectedDate, 0).toUpperCase()}</Typography>
                    <ArrowForward className={classes.arrowHover} color="secondary" fontSize={"large"} onClick={()=>{handleDateForward()}} />
                </div>
            </div>
        </div>
    )
}
