import React, { useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import { AppContext } from "~context";
import translate from '../../i18n/translate';

import { MobileDrawerMenu } from '../navigation/navMenu/MobileDrawerMenu';
import { MobileOptionsMenu } from '../navigation/navMenu/MobileNavOptions';

export default function ButtonAppBar({setLanguageCookie}) {
    const { setMobileMenuOpen, setMobileOptionsOpen, navList } = useContext(AppContext);

    const theme = useTheme();
    const location = useLocation();

    const checkActiveLink = () => {
        const currentPage = navList.filter(item => item.link === location.pathname);
        return currentPage.length > 0 ? translate(currentPage[0].name) : location.state.name;
        //return translate(currentPage[0].name)
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar
            position="static"
            color='secondary'
            elevation={0}
            >
            <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="neutral"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={()=> {
                    console.log("filter open");
                    setTimeout(()=>{setMobileMenuOpen(true)},200);
                    }
                }
            >
                <MenuIcon />
            </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: theme.palette.neutral.main, fontFamily: 'Poppins', textTransform: 'capitalize' }}>
                    {checkActiveLink()}
                </Typography>
            <IconButton
                size="large"
                edge="end"
                color="neutral"
                aria-label="menu"
                sx={{ ml: 2 }}
                onClick={()=> {
                    console.log("options open");
                    setTimeout(()=>{setMobileOptionsOpen(true)},200);
                    }
                }
            >
                <MoreVertIcon />
            </IconButton>
            </Toolbar>
        </AppBar>
        <MobileDrawerMenu />
        <MobileOptionsMenu />
        </Box>
    );
}