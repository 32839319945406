import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]:{
    'login': 'LOGIN',
    'singUp': 'SIGN UP',
    'logout': 'log out',
    'menu': "MENU",
    'name': 'Name',
    'points': 'Credits',
    'date': 'Date',
    'filters': 'filters',
    'announcement': 'announcement',
    'announcements': 'announcements',
    'resetFilters': 'Reset Filters',
    'events': "Events",
    'allEvents': 'All Events',
    'achievedEvents': 'Achieved Events',
    'archivedEvents': 'Archived events',
    'upcomingEvents': 'Upcoming Events',
    'addNewEvent': 'Add New Event',
    'addNewEventUpperCase': 'ADD NEW EVENT',
    'searchEvents': 'Search Events',
    'searchEventsUpperCase': 'SEARCH EVENTS',
    'eventName': 'Event Name',
    'eventNameUpperCase': 'EVENT NAME',
    'eventFormat': 'event format',
    'uploadCSV': 'Upload CSV',
    'uploadCSVUpperCase': 'UPLOAD CSV',
    'schoolName': 'School Name',
    'schoolNameUpperCase': 'SCHOOL NAME',
    'school': 'School',
    'SCHOOLCAP': 'SCHOOL',
    "download-card-title": "DOWNLOAD THE LISD APP",
    "rewards": "Rewards",
    "view": "view",
    "view events": "VIEW EVENTS",
    "view rewards": "VIEW REWARDS",
    "VIEW CALENDAR": "VIEW CALENDAR",
    'viewCalendarUpperCase': 'VIEW CALENDAR',
    "redeem now": "REDEEM NOW",
    "redeem": "redeem",
    "credits": "credits",
    "dashboard": "dashboard",
    "calendar": "calendar",
    "parents": "parents",
    "resources": "Resources",
    "options": "options",
    "notes": "notes",
    'addANote': 'Add a Note',
    "account": "account",
    "gradebook": "gradebook",
    'students': 'students',
    "unlocked": "unlocked",
    "locked": "locked",
    "start time": "start time",
    "end time": "end time",
    "availableCredits": "available credits",
    "total available credits": "total available credits",
    'redeemedCredits': 'redeemed credits',
    'totalCreditsRedeemed': 'Total credits redeemed',
    'creditRequests': 'credit requests',
    "VIEW CREDIT HISTORY": "VIEW CREDIT HISTORY",
    "CHECK IN": "CHECK IN",
    "REDEEM POINTS": "REDEEM CREDITS",
    'email': 'Email',
    'emailCap': 'EMAIL',
    'password': 'Password',
    'signIn': 'Sign in',
    'accountDescription': "Don't have a gradebook account set one up here",
    'changeLanguage': 'Change Language',
    'dataUpdateMidnight': 'Data updated every day at midnight',
    'schools': 'Schools',
    'eventCategories': 'Event Categories',
    'categories': 'Categories',
    'categoriesUpperCase': 'CATEGORIES',
    'academic': 'Academic',
    'athletic': 'Athletic',
    'gradeLevel': 'Grade Level',
    'allCampus': 'All Campus',
    'aboutToUse': 'You are about to use ---- for this item',
    'eventList': 'Event List',
    'january': 'January',
    'february': 'February',
    'march': 'March',
    'april': 'April',
    'may': 'May',
    'june': 'June',
    'july': 'July',
    'august': 'August',
    'september': 'September',
    'october': 'October',
    'november': 'November',
    'december': 'December',
    'today': 'Today',
    'resetFilters': 'Reset Filters',
    'viewMore': 'View more',
    'calendar': 'Calendar',
    'selectDate': 'Select date for event details',
    'monday': 'Monday',
    'mMonday': 'M',
    'tuesday': 'Tuesday',
    'tTuesday': 'T',
    'wednesday': 'Wednesday',
    'wWednesday': 'W',
    'thursday': 'Thursday',
    'tThursday': 'T',
    'friday': 'Friday',
    'fFriday': 'F',
    'saturday': 'Saturday',
    'sSaturday': 'S',
    'sunday': 'Sunday',
    'sSunday': 'S',
    'noteSubject': 'Note Subject',
    'eventDetails': 'Event Details',
    'onlineEvent': 'Online Event',
    'inPersonEvent': 'In Person Event',
    'thankYouForCheckIn': 'Thank you checking in to',
    'english': 'English',
    'spanish': 'Spanish',
    'manage': 'Manage',
    'manageEvents': 'Manage Events',
    'manageEventsUpperCase': 'MANAGE EVENTS',
    'manageRewards': 'Manage Rewards',
    'manageRewardsUpperCase': 'MANAGE REWARDS',
    'manageResources': 'Manage Resources',
    'manageResourcesUpperCase': 'MANAGE RESOURCES',
    'eventDate': 'Event Date',
    'eventDateUpperCase': 'EVENT DATE',
    'addNewReward': 'Add New Reward',
    'nameOfReward': 'Name of Reward',
    'rewardName': 'Reward Name',
    'rewardNameUpperCase': 'REWARD NAME',
    'quantity': 'Quantity',
    'quantityUpperCase': 'QUANTITY',
    'quantityRemainingUpperCase': 'QUANTITY REMAINING',
    'remainingUpperCase': 'REMAINING',
    'pointsUpperCase': 'CREDITS',
    'pointsToRedem': 'Credits to redem',
    'description': 'Description',
    'urlOfThePhoto': 'URL OF THE PHOTO',
    'save': 'Save',
    'addNewResource': 'Add new resource',
    'resourceName': 'Resource Name',
    'resourceNameUpperCase': 'RESOURCE NAME',
    'resourceLink': 'Resource Link',
    'resourceLinkUpperCase': "RESOURCE LINK",
    'parentCreditHistory': 'Parent Credit History',
    'totalParents': 'Total Parents',
    'filtersBy': 'Filters By',
    'parentName': 'Parent Name',
    'parentNameUpperCase': 'PARENT NAME',
    'totalCredits': 'Total Credits',
    'totalCreditsUpperCase': 'TOTAL CREDITS',
    'viewHistory': 'View History',
    'backToParents': 'Back to parents',
    'eventRequestCredit': "Don't see an event? Request credit(s)",
    'edit': 'Edit',
    'delete': 'Delete',
    'creditsToRedeem': 'Credits to redeem',
    'saveChanges': 'Save changes',
    'SAVE CHANGES': 'SAVE CHANGES',
    'deleteEvent': 'Delete event',
    'uploadImage': 'Upload image',
    'UPLOAD IMAGE': 'UPLOAD IMAGE',
    'eventDescription': 'Event description',
    'eventStart': 'Event Start',
    'eventEnd': 'Event end',
    'streetAddress': 'Street Address',
    'eventLatitude': 'Event latitude',
    'eventLongitude': 'Event longitude',
    'eventCategory': 'Event category',
    'aboutToDelete': 'You are about to delete',
    'actionUndone': 'This action cannot be undone',
    'likeToContinue': 'Would you like to continue?',
    'yes': 'Yes',
    'no': 'No',
    'downloadApp': 'Download the LISD app',
    'none': "none",
    'FIRST NAME': 'FIRST NAME',
    'LAST NAME': 'LAST NAME',
    'website': 'website',
    'Required': 'Required',
    //LOGIN ERRORS
    'user not found': 'user not found',
    'wrong password': 'wrong password',
    'internal error': 'internal error',
    //EVENT FORM VALIDATION ERRORS
    'SCHOOL NAME IS REQUIRED': 'SCHOOL NAME IS REQUIRED',
    'MUST UPLOAD AN IMAGE': 'MUST UPLOAD AN IMAGE',
    'EVENT NAME IS REQUIRED': 'EVENT NAME IS REQUIRED',
    'WEBSITE IS REQUIRED FOR ONLINE EVENTS': 'WEBSITE IS REQUIRED FOR ONLINE EVENTS',
    'DESCRIPTION IS REQUIRED': 'DESCRIPTION IS REQUIRED',
    'DATE IS REQUIRED': 'DATE IS REQUIRED',
    'START TIME IS REQUIRED': 'START TIME IS REQUIRED',
    'END TIME IS REQUIRED': 'END TIME IS REQUIRED',
    'EVENT CATEGORY IS REQUIRED': 'EVENT CATEGORY IS REQUIRED',
    'CREDITS IS REQUIRED': 'CREDITS IS REQUIRED',
    'ADDRESS IS REQUIRED': 'ADDRESS IS REQUIRED',
    'LATITUDE IS REQUIRED': 'LATITUDE IS REQUIRED',
    'LONGITUDE IS REQUIRED': 'LONGITUDE IS REQUIRED',
    'EVENT TYPE IS REQUIRED': 'EVENT TYPE IS REQUIRED',
    //DELETE MODAL
    'You are about to delete': 'You are about to delete',
    'This action cannot be undone!': 'This action cannot be undone!',
    'Would you like to continue?': 'Would you like to continue?',
    //REWARD FORM VALIDATION ERRORS
    'SCHOOL IS REQUIRED': 'SCHOOL IS REQUIRED',
    'COST IS REQUIRED': 'COST IS REQUIRED',
    'QUANTITY IS REQUIRED': 'QUANTITY IS REQUIRED',
    'DESCRIPTION IS REQUIRED': 'DESCRIPTION IS REQUIRED',
    'NAME IS REQUIRED': 'NAME IS REQUIRED',
    'DELETE': 'DELETE',
    'CANCEL': 'CANCEL',
    'TOTAL': 'TOTAl',
    'AVAILABLE': 'AVAILABLE',
    'REDEEMED': 'REDEEMED',
    'forgotpassword': 'Forgot Password',
    'resetpassword': 'Reset Password',
    'resetdescription': "Enter your email address and we'll send you a link to reset your password.",
    'emailaddress': 'Email Address',
    'submit': 'SUBMIT',
    'returntologin': 'Return to Login',
    'title': 'Title',
    'addNewAnnouncementUpperCase': 'ADD NEW ANNOUNCEMENT',
    'Event Start': 'Event Start',
    'state': 'State',
    'city' : 'City',
    'School Name' : 'School Name',
    'Event Date' : 'Event Date',
    'Event End' : 'Event End',
    'UPLOAD SIGN UP PDF' : 'UPLOAD SIGN UP PDF',
    // NETWORK ERROR
    "network request failed": "Network request failed",
    'delete database' :  'Delete Database',
    'deletewarning' : ' Warning: You are about to reset ParentU for a new school year. This action is permanent. All parents, students,points, and events will be removed. Do you wish to proceed?',
    'notewarning' :   ' Note: Please disable your export of student and parent data until you are ready to load the new parents and students into the system. ',
    'Are you sure!' : 'Are you sure!',
    'deletingdata' : 'Deleting data, please wait...',
    'You have successfully deleted below collections:' : 'You have successfully deleted below collections:',
    'checkinCountUpperCase' : "CHECK-IN COUNT",
    'descriptionUpperCase': 'DESCRIPTION',
    'dateUpperCase': "DATE",
    "titleUpperCase": "TITLE",
    'eventImage': "Event Image:",
    'eventPDF': "Event PDF:",
    'Print QR Code' : 'Print QR Code',
    'UPLOAD SIGN UP PDF' : 'UPLOAD SIGN UP PDF',
    'EXPORT EVENT' : 'EXPORT EVENT',
    'DELETE EVENT' : 'DELETE EVENT',
    'Paper SignUp Sheet' : "Paper SignUp Sheet",
    'Remove PDF' : 'Remove PDF',
    'Remove Image' : 'Remove Image',
    'QR Code' : 'QR Code:',
    'PDF FILE IS REQUIRED': 'PDF FILE IS REQUIRED',
    'IMAGE FILE IS REQUIRED': 'IMAGE FILE IS REQUIRED',
    //reward-form
    'Edit Reward': 'Edit Reward',
    'Parents Redeemed' : 'Parents Redeemed',
    'Credits are required' : 'Credits are required',
    "ViewRequest" : "View Request",
    "Required" : "Required",
    'scanQRCode' : 'Scan this QR code to check in and receive rewards',
    'descriptionRequired': "Description is required",
    "Approve": "Approve",
    "Cancel": "Cancel",
    "veiwHistory" : "VIEW HISTORY",
    "Parent Name":  "Parent Name",
    "Parent Email": "Parent Email",
    "Select All" : "Select All",
    "Parents Check In Count": "Parents Check In Count",

  }
}