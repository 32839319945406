import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './table.css';
import { columns, redeemedColumns } from './table-columns';
const DataTable = ({ data }) => {
  
  return (
    <>
      <div
        style={{
          height: 600,
          width: '100%',
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <DataGrid
          rows={ data }
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          rowHeight={85}
          sx={{ border: 'none', color: '#3B3C86' }}
          disableColumnMenu={true}
        />
      </div>
    </>
  );
};
const RedeemedDataTable = ({ data }) => {
  return (
    <>
      <div
        style={{
          height: 600,
          width: '100%',
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <DataGrid
          rows={ data }
          columns={redeemedColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          rowHeight={85}
          sx={{ border: 'none', color: '#3B3C86' }}
          disableColumnMenu={true}
        />
      </div>
    </>
  );
};

export {DataTable, RedeemedDataTable};
