
import { db } from '../../firebase';
import { collection, getDocs, getDoc } from 'firebase/firestore';

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
const titleCase = (str) => {
  if(str === "Select All") {
    return "All Schools";
  }
  return str.toLowerCase()
  .split(' ')
  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');
  
}

//ABILITY TO QUERY EVENTS BY WHETHER THEY ARE IN THE PAST OR NOT(activeFilter), OR BY A SPECIFIC DATE(date)
const getEvents = async (activeFilter, date) => {
    //Todays date for compare
    const today = new Date();
    today.setHours(0,0,0,0);
    const results = []

    try {
      const queryRef = collection(db, 'events');
      const eventsSnapshot = await getDocs(queryRef);

      eventsSnapshot.forEach((doc) => {
        const event = {
          id: doc.id ?? null,
          date: doc.data().date && doc.data().date,
          dateString: doc.data().date.toDate().toDateString(),
          compareDate: doc.data().date ? doc.data().date.toDate() : '',
          description: doc.data().description ?? '',
          endTime: doc.data().endTime ?? '',
          eventCategory: doc.data().eventCategory ?? '',
          eventLat: doc.data().eventLat ?? '',
          eventLong: doc.data().eventLong ?? '',
          image: doc.data().image ?? '',
          name: doc.data().name ?? '',
          reward: doc.data().reward ?? '',
          school: doc.data().school ? titleCase(doc.data().school) : '',
          startTime: doc.data().startTime ?? '',
        };

        // Filter: filtering by upcoming, archived and all events
        if (activeFilter === 'Upcoming Events') {
          if (today <= event.compareDate) {
            results.push(event);
          }
        }
        // If event is in the past
        else if (activeFilter === 'Archived Events') {
          if(event.name === "Teacher meet and Greet") {

            // console.log("today", today.getTime())
            // console.log("compare day", event.compareDate.getTime())
          }
          if (today.getTime() > event.compareDate.getTime()) {
            results.push(event);
          }
        } 
        //Filtering by a specific date
        else if(date) {
          if (date === event.compareDate) {
              results.push(event);
          }
        }
        // All events
        else {
          results.push(event);
        }
      });

      return results

    } catch (error) {
      console.log('ERROR!!', error);
    }
  };

  export {
    getEvents,
}