import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';


//GETS THE DOCUMENTS COUNT OF A SPECIFIC COLLECTION
const getCount = async (collectionRef) => {
    try {
      const queryRef = collection(db, collectionRef);
      const snapshot = await getDocs(queryRef);

      return snapshot.docs.length
    }
    catch (error) {
        console.log(`error getting ${collection} count`, error)
    }   
}

export {
    getCount,
}