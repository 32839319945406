import { collection, startAfter, query, limit, getDocs} from "firebase/firestore";
import { db } from '../../firebase';
import { resolveParentData } from "../../functions/queries";

export const subscribeParents = async (parents, setParents, lastVisible, setLastVisible, setLoading) => {

  try {
    // console.log('lastVisible', lastVisible);
    // const parentsRef = collection(db, `parents`);
    setLoading(true);
    const parentsRef = lastVisible ? query(collection(db, `parents`),startAfter(lastVisible), limit(300)) : query(collection(db, `parents`), limit(300)); 
    const documentSnapshots = await getDocs(parentsRef);
    // console.log('documentSnapshots.docs', documentSnapshots.docs);
    if(documentSnapshots.docs.length > 0){
      // console.log('documentSnapshots.docs', documentSnapshots.docs);
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length-1]);
      resolveParentData(documentSnapshots.docs, setParents, parents);
      // snapshot.docs.forEach((parent) => {
      //   const creditRequestsRef = collection(db, `parents/${parent.data().parentId}/request-history`);
      //   onSnapshot(creditRequestsRef, (parentSnapshot) => {
      //     resolveParentData(snapshot.docs, setParents);
      //   })
      // }) 
    } else {
      setLoading(false);
    }
    
   /*
    /onSnapshot(query(
      parentsRef,
      limit(500)
  ), (snapshot) => {
      console.log("PARENTS ON SNAPSHOT TRIGGERED")
      if(snapshot.docs.length > 0){
        resolveParentData(snapshot.docs, setParents);
        // snapshot.docs.forEach((parent) => {
        //   const creditRequestsRef = collection(db, `parents/${parent.data().parentId}/request-history`);
        //   onSnapshot(creditRequestsRef, (parentSnapshot) => {
        //     resolveParentData(snapshot.docs, setParents);
        //   })
        // }) 
      }
    })*/
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO PARENTS COLLECTION", error);
  }
};