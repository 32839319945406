import React, { useState, useEffect, useContext } from "react";
import Calendar from "react-calendar";
import { makeStyles } from "@mui/styles";
// import { theme } from '../../utils/theme';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Skeleton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { getEvents } from "~functions/queries";
import { addEventsToCalendar } from "~functions/calendar";

import { MarkedDate } from './MarkedDate'
import {CalendarDayDetail} from "./CalendarDayDetail";

import translate from '~translate';
import { AppContext } from '~context';

import "./eventCalendar.css";
import { MasksRounded } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  calendarContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    paddingBottom: 100,
  },
  spinnerContainer: {
    width: "100%",
    height: "65vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: 'center',
  },
  detailContainer: {
    position: 'absolute',
    left: 5,
    top: 0,
    // m:1,
    width: "99%",
    height: "100%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      left: 0,
      height: "100%",
    },
  }
}));

const styles = {
    reset: {
        mt: 3,
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        fontWeight: 800,
        textDecoration: 'underline',
        "&:hover": {
            cursor: 'pointer',
            opacity: "0.8"
        }
    },
    title: {
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        textAlign: 'center',
        maxWidth: "80vw",
        paddingTop: "10px"
    },
}

export const EventCalendar = () => {
  const [checked, setChecked] = useState(false);
  const [value, onChange] = useState(new Date());
  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null)
  const { locale, allEvents, allStudents, students } = useContext(AppContext)
  const [mark, setMark] = useState([]);
  const [events, setEvents] = useState([])

  const getData = async () => {
    //GET EVENTS FROM THE DATABASE
    const eventsSnapshot = await getEvents();
    if(eventsSnapshot.length) {
      //ADD THE EVENT MARKS TO THE CALENDAR ITSELF
      setEvents(eventsSnapshot)
      setMark(addEventsToCalendar(eventsSnapshot));

    } else {
      setMark([]);
    }

  };

  useEffect(() => {
   // console.log("SELECTED DATE", selectedDate)
  },[selectedDate])
  

  const classes = useStyles();
  const theme = useTheme();

  const showMobile = useMediaQuery(theme.breakpoints.down('md'));

  const formatMarkDate = (date) => {
      // console.log(date);
      return `${(date.getMonth() + 1).toString()}-${date
        .getDate()
        .toString()}-${date.getFullYear()}`;
  };

  const setEventsForDate = (date, action) => {
    //let dateEvents = events.filter((event) => Date.parse(new Date(event.date.seconds * 1000)) === Date.parse(new Date(date)))
    let dateEvents = events.filter((event) => Date.parse(formatDate(event.dateString)) === Date.parse(formatDate(date)));
   //console.log(dateEvents)
    setSelectedDate({
      date: date,
      events: dateEvents
    })
    if(action === "open") {
      setChecked(!checked)
     // console.log(`dateEvents are ${events}`)
    }
  };
  const formatDate = (date) => {
    const options = { year: "numeric", month: "numeric", day: "numeric"};
    const dateToConvert = new Date(date);
    return dateToConvert.toLocaleDateString("en-US", options);
  };
  const calendarDetail = (
    <Paper className={classes.detailContainer} elevation={0}>
        <CalendarDayDetail
          showDayDetail={checked} 
          setShowDayDetail={setChecked} 
          selectedDate={selectedDate} 
          setSelectedDate={setSelectedDate}
          setEventsForDate={setEventsForDate}
          getData={getData}
        />
    </Paper>
  )

  const dayAbbv = {
    "en-us": ["S", "M", "T", "W", "T", "F", "S"],
    "es-es": ["D", "L", "M", "M", "J", "V", "S"]
  }

  useEffect(() => {
    // if ((events.length && students.length && mark.length) || (allEvents.length && allStudents.length && !mark.length)) {
    //   setLoadingCalendar(false)
    // }
   // console.log("mark", mark)

  }, [events, students, mark])

  useEffect(() => {
    getData();
  },[])

  return (
    <div className={classes.calendarContainer}>
        {loadingCalendar ? 
        <div className={classes.spinnerContainer}>
          <Skeleton variant="text" width={"90%"} height={150} animation="wave" sx={{mb: 2}}/>
          <Skeleton variant="rectangular" width={"90%"} height={"100%"} animation="wave" />
        </div>
        :
        <>
          <Typography variant={showMobile ? "body2" : "h6"} color="secondary" sx={styles.title}>{translate("selectDate")}</Typography>
          <Calendar
              onChange={onChange}
              value={value}
              nextLabel={showMobile ? <ArrowForwardIosIcon color="secondary" fontSize="small" /> : <ArrowForward color="secondary" />}
              next2Label={null}
              prevLabel={showMobile ? <ArrowBackIosNewIcon color="secondary" fontSize="small" /> : <ArrowBack color="secondary" />}
              prev2Label={null}
              locale={locale}
              showDoubleView={false}
              formatShortWeekday={(locale, date) =>
              dayAbbv[locale][date.getDay()]
              }
              calendarType='iso8601' 
              minDetail="year"
              tileContent={({ date, view }) => {
                if(view === "month") {
                  if (mark.length && mark.find((x) => x === formatMarkDate(date))) {
                    //DATES IN THE CALENDAR WITH AT LEAST ONE MATCHING EVENT DATE RENDER THE MarkedDate COMPONENT.
                    return <MarkedDate date={date} events={events} view={view}/>;
                  }
                } else {
                  if (mark.length && mark.find((x) => new Date(x).getMonth() === date.getMonth() && new Date(x).getFullYear() === date.getFullYear())) {
                    //DATES IN THE CALENDAR WITH AT LEAST ONE MATCHING EVENT DATE RENDER THE MarkedDate COMPONENT.
                    return <MarkedDate date={date} events={events} view={view}/>;
                  }
                }
              }}
              onClickDay={(value) => {
                // console.log(formatMarkDate(new Date(value)));
                setEventsForDate(value, "open");
              }}
          />
        </>
        }
        <Slide direction="down" in={checked} mountOnEnter unmountOnExit onExited={() => setSelectedDate(null)}>
          {calendarDetail}
        </Slide>
    </div>
  );
};
