
import React, {useEffect, useState, lazy, Suspense, useContext} from 'react';
import { makeStyles } from '@mui/styles';
import { AppContext } from '~context';
import { Button, Grid, Stack } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import translate from '~translate';
const TableComponent = lazy(() =>
  import('../../components/announcements/announcements-table')
);

const AnnouncementForm = lazy(() =>
  import('../../components/announcements/announcement-form/index')
);

const useStyles = makeStyles(() => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '140px',
    color: '#3B3C86',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '70px',
    minWidth: '100%',
    padding: '20px'
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Announcements = () => {
  
  const classes = useStyles();
  const {language, announcementFormOpen, setAnnouncementFormOpen, setFlag } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  //console.log(rows)

  const getAnnouncements = async () => {
    try {
      let tableRows = [];
      const queryRef = collection(db, 'announcements');
      const announcementsSnapshot = await getDocs(queryRef);
      announcementsSnapshot.forEach((doc) => {
        const announcement = {
          id: doc.id ?? null,
          title: doc.data().title ?? '',
          school: doc.data().school ? doc.data().school === 'Select All' ? 'All Schools' : doc.data().school : '',
          description: doc.data().description ?? '',
          date: doc.data().date.toDate().toDateString() ?? ''
        };
        //console.log('announcement', announcement);
        //tableRows =[...tableRows, announcement];
        tableRows.push(announcement);
        }
      );
      setRows(tableRows);
      return tableRows;
    } catch (error) {
      console.log('ERROR!!', error);
    }
  };

  const updateAnnouncements = () => {
    setRows([]);
    getAnnouncements();
  };

  const addAnnouncement = async () => {
    await Promise.all([setFlag(false), setAnnouncementFormOpen(true)]);
  }

  useEffect(() => {
    getAnnouncements().then((res) => setRows(res))
  }, []);

  
  
  return (
    <>
    {announcementFormOpen ? (
        <Suspense fallback={<div>Loading..........</div>}>
          <AnnouncementForm getData={updateAnnouncements} />
        </Suspense>
      ) : (
      <Grid container rowSpacing={0}>
      <Grid item xs={12} className={classes.searchContainer}>
      <Stack direction='row' spacing={0.5}>
            <Button
              variant='outlined'
              aria-label='add new event'
              className={classes.buttonLeft}
              onClick={addAnnouncement}
              style={{
                marginRight: '15px',
                borderRadius: '0',
                border: '1px solid #AF1608',
                opacity: 1,
                fontSize: '10px',
              }}
            >
              {translate('addNewAnnouncementUpperCase')}
            </Button>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ width: '100%', minHeight: '500px', overflow: 'hidden' }}
            className={classes.tableContainer}
          >
            <Suspense fallback={<div>Loading..........</div>}>
              <TableComponent data={rows} language={language} />
            </Suspense>
          </Grid>
        </Grid>
  )
            }
    </>
  );
};

export default Announcements;