import React, { useContext, useState, useEffect, Suspense, lazy } from 'react';
import { AppContext } from '~context';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
import SearchBar from '../searchBar/index';
import Filter from '../filter/index';
import { getEvents } from "~functions/queries";
import DaySelect from './DaySelect';

const TableComponent = lazy(() => import('../events-table'));
const EventsForm = lazy(() => import('../events-form/index'));



export const CalendarDayDetail = ({ showDayDetail, setShowDayDetail, selectedDate, setSelectedDate, setEventsForDate, getData }) => {
  const { eventFormOpen } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [searchEvents, setSearchEvents] = useState([]);

  // const classes = useStyles();
  const theme = useTheme();

  const clearSearch = () => {
    setSearch("");
    setSearchActive(false);
    setSearchEvents([]);
  };

  const updateEvents = async () => {
    setRows([]);
    // const tableRows = await getEvents(null, date);
    // setRows(tableRows)
  };

  useEffect(() => {
    updateEvents();
  }, []);

  return (
    <Box 
    sx={{
      height: '100%',
      width: '100%',
      maxWidth: {
        xs: '100%',    // small screens
        sm: '90%',     // small-medium screens
        md: '80%',     // medium screens
        lg: '90%',     // large screens
        xl: '90%'      // extra-large screens
      },
      margin: '0 auto',
      marginTop:2,
      paddingBottom:100
    }}>
      {eventFormOpen ? (
        <Suspense fallback={<div>Loading..........</div>}>
          <div >
            <EventsForm getEvents={updateEvents} />
          </div>
        </Suspense>
      ) : (
        <div>
          <DaySelect 
            showDayDetail={showDayDetail} 
            setShowDayDetail={setShowDayDetail}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setEventsForDate={setEventsForDate}
            getData={getData}
            clearSearch={clearSearch}
          />
          <Grid container rowSpacing={0}>
            <Grid item xs={12} >
              <SearchBar 
                getEvents={updateEvents} 
                events={selectedDate.events} 
                searchEvents={searchEvents} 
                setSearchEvents={setSearchEvents} 
                setSearchActive={setSearchActive}
                search={search}
                setSearch={setSearch}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ width: '100%', minHeight: '500px', overflow: 'hidden' }}
              
            >
              <Suspense fallback={<div>Loading..........</div>}>
                <TableComponent data={searchActive ? searchEvents : selectedDate.events} />
              </Suspense>
            </Grid>
          </Grid>
        </div>
      )}
    </Box>
  );
};
