import React, { useContext, useState, useEffect, Suspense, lazy } from 'react';
import { AppContext } from '~context';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Grid } from '@mui/material';
import ActionBar from '../../components/resources/action-bar';
const TableComponent = lazy(() =>
  import('../../components/resources/resources-table')
);

const ResourceForm = lazy(() =>
  import('../../components/resources/resource-form')
);


const useStyles = makeStyles(() => ({
  actionBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '70px',
    minWidth: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Resources = () => {
  const { resourceFormOpen } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  let tableRows = [];

  const getResources = async () => {
    try {
      const queryRef = collection(db, 'resources');
      const eventsSnapshot = await getDocs(queryRef);
      eventsSnapshot.forEach((doc) => {
        const event = {
          id: doc.id ?? null,
          name: doc.data().name ?? '',
          schoolName: doc.data().schoolName ? doc.data().schoolName === 'Select All' ? 'All Schools' : doc.data().schoolName : '',
          description: doc.data().description ?? '',
          url: doc.data().url ?? ''
        };
        tableRows.push(event);
        }
      );
      setRows(tableRows);
    } catch (error) {
      console.log('ERROR!!', error);
    }
  };

  const updateResources = () => {
    setRows([]);
    getResources();
  };

  useEffect(() => {
    updateResources();
  }, []);

  return (
    <>
    {resourceFormOpen ? (
        <Suspense fallback={<div>Loading..........</div>}>
          <ResourceForm getResources={updateResources} />
        </Suspense>
      ) : (
      <Grid container rowSpacing={0}>
        <Grid item xs={12} className={classes.actionBarContainer}>
          <ActionBar getResources={updateResources}/>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ width: '100%', minHeight: '500px', overflow: 'hidden' }}
          className={classes.tableContainer}
        >
          <Suspense fallback={<div>Loading..........</div>}>
            <TableComponent data={rows} />
          </Suspense>
        </Grid>
      </Grid>
      )}
    </>
  );
};

export default Resources;
