const useStyles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  type: {
    display: 'flex',
    justifyContent: 'center',
  },
  inputButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 300,
    justifyContent: 'space-around',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 175,
  },
  formContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '75%',
    // backgroundColor: "red"
  },
  logo: {
    width: 225,
    height: 225,
  },
};

export default useStyles;
