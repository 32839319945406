import { db } from '../../firebase';
import { collection, onSnapshot, getDocs, doc } from "firebase/firestore";

const getCreditRequests = async(parentId) => {
    const collectionRef = collection(db, `parents/${parentId}/request-history`);
    const req = await getDocs(collectionRef);
    const requests = [];
    if(req.docs.length>0){
      req.docs.map((credit, idx) => {
        const row = {id: credit.id, ...credit.data()};
        requests.push(row);
      })
    }
    return requests;
}

export {getCreditRequests}