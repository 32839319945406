import React, { useContext, useState, useEffect, Suspense, lazy } from 'react';
import { AppContext } from '~context';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import SearchBar from '../../components/events/searchBar/index';
import Filter from '../../components/events/filter/index';
import { getEvents } from "~functions/queries";
const TableComponent = lazy(() =>
  import('../../components/events/events-table')
);
const EventsForm = lazy(() =>
  import('../../components/events/events-form/index')
);


const useStyles = makeStyles(() => ({
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '140px',
    color: '#3B3C86',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '70px',
    minWidth: '100%',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Events = () => {
  const { eventFormOpen, activeFilter, events, setEvents, language, parents } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [searchEvents, setSearchEvents] = useState([]);

  const classes = useStyles();
  const theme = useTheme();

  const getCheckCount = async (id) => {
    let count = 0;
    parents.forEach(parent => {
      parent.creditHistory.forEach(item => {
        if(item.id === id) {
          count += 1;
        }
      })
    });
    return count;
  }

  const updateEvents = async () => {
    setEvents([]);
    const tableRows = await getEvents(activeFilter);
    await Promise.all(tableRows.map(async (event) => {
      event.checkInCount = await getCheckCount(event.id);
      return;
    }))
    setEvents(tableRows);
  };

  useEffect(() => {
    updateEvents();
  }, [activeFilter]);

  return (
    <>
      {eventFormOpen ? (
        <Suspense fallback={<div>Loading..........</div>}>
          <EventsForm getEvents={updateEvents} />
        </Suspense>
      ) : (
 
        <Grid container rowSpacing={0}>
          <Grid item xs={12} className={classes.filterContainer}>
            <Filter />
          </Grid>
          <Grid item xs={12} className={classes.searchContainer}>
            <SearchBar 
              getEvents={updateEvents} 
              events={events} 
              searchEvents={searchEvents} 
              setSearchEvents={setSearchEvents} 
              setSearchActive={setSearchActive}
              search={search}
              setSearch={setSearch}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ width: '100%', minHeight: '500px', overflow: 'hidden' }}
            className={classes.tableContainer}
          >
            <Suspense fallback={<div>Loading..........</div>}>
              <TableComponent data={searchActive ? searchEvents : events} language={language} />
            </Suspense>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Events;

