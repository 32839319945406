import { db } from '../../firebase';
import { collection, onSnapshot, getDocs, doc } from "firebase/firestore";

const getCreditHistory = async(parentId) => {
    const collectionRef = collection(db, `parents/${parentId}/credits-history`);
    const credits = await getDocs(collectionRef);
    const creditHistory = [];
    if(credits.docs.length>0){
      credits.docs.map((credit, idx) => {
        const row = {id: credit.id, ...credit.data()};
        creditHistory.push(row);
      })
    }
    return creditHistory;
}

export {getCreditHistory}