const useStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  drawerPaper: {
    minHeight: '100vh',
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '6px 0 4px -4px rgba(79,79,79,0.3)',
    overflow: 'hidden',
  },
};

export default useStyles;
