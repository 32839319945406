import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import { useCookies } from "react-cookie";


export const Header = ({ title=null }) => {
  const [cookies, setCookie] = useCookies(["language"]);

  const setLanguageCookie = (value) => {
    // console.log("value", value)
    setCookie("language", value, {
      path: "/"
    });
  }

  const theme = useTheme();
  const showMobile = useMediaQuery(theme.breakpoints.down('md'));

  return showMobile ? <MobileHeader setLanguageCookie={setLanguageCookie}/> : <DesktopHeader setLanguageCookie={setLanguageCookie}/>;
}
