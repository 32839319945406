
import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AppContext } from "~context";
import { useTheme } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import translate from '../../../i18n/translate';
import useMediaQuery from '@mui/material/useMediaQuery';

// import { DividerLine } from '../../dividerLine';
// import { NavFooter } from '../navFooter';

const useStyles = makeStyles(theme => ({
    menuTitleContainer: {
        width: "100%",
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuTitle: {
        flex: 1,
        textAlign: 'center',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: 'Poppins'
    },
    navContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: "column",
        justifyContent: "space-between",
    },
}));


export const MobileDrawerMenu = () => {
    const { mobileMenuOpen, setMobileMenuOpen, navList, language, setLanguage, setLocale } = useContext(AppContext);

    const theme = useTheme();
    const classes = useStyles();
    const auth = getAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const showOnTabletVert = useMediaQuery(theme.breakpoints.up('sm'));

    const onSignout = () => {
        signOut(auth)
            .then(() => {
                // Sign-out successful.
                setMobileMenuOpen(false);
                navigate("/");
            }).catch((error) => {
                // An error happened.
            });
    }

    const navigateTo = (link) => {
        // console.log('link', link);
        setMobileMenuOpen(false);
        setTimeout(() => {
            navigate(link);
        }, 250)
    }

    const checkActiveLink = (item) => {
        if (item.link === location.pathname) {
            return {
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                fontSize: 18,
                paddingLeft: 1,
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
            }
        } else {
            return {
                color: theme.palette.secondary.main,
                paddingLeft: 1,
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
            }
        }
    }

    const handleChange = (event) => {
        setLanguage(event.target.value);
        setLocale(event.target.value);
    };

    return (
        <SwipeableDrawer
            anchor={'left'}
            open={mobileMenuOpen}
            disableSwipeToOpen={true}
            onClose={() => setMobileMenuOpen(false)}
            onOpen={() => setMobileMenuOpen(true)}
            disableDiscovery={true}
        >
            <div className={classes.navContainer}>
                <Box
                    sx={showOnTabletVert ? { width: '35vw' } : { width: '55vw' }}
                    role="presentation"
                >
                    <div className={classes.menuTitleContainer}>
                        <Typography variant="h6" component="div" className={classes.menuTitle}>
                            {translate('menu')}
                        </Typography>
                    </div>
                    <List>
                        {navList.map((text, index) => (
                            <ListItem key={text.name} onClick={() => navigateTo(text.link)} >
                                <ListItemButton>
                                    <ListItemText disableTypography={true} primary={translate(text.name)} sx={checkActiveLink(text)} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{ width: '45%', paddingLeft: 3, }}>
                        <FormControl fullWidth variant="standard">
                            <Select
                                id="language-select"
                                value={language}
                                onChange={handleChange}
                                disableUnderline
                                style={{ color: theme.palette.secondary.main, fontWeight: 400, fontSize: '0.9rem', fontFamily: "Poppins" }}
                                sx={{ color: theme.palette.primary.main, "& .MuiSvgIcon-root": { color: theme.palette.secondary.main, } }}
                            >
                                <MenuItem value={'en-us'}>ENGLISH</MenuItem>
                                <MenuItem value={'es-es'}>ESPAÑOL</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </div>
        </SwipeableDrawer>

    );
}