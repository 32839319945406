import { collection, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

const titleCase = (str) => {
  return str.toLowerCase()
  .split(' ')
  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');
}

export const subscribeEvents = (setEvents, activeFilter, date) => {
  const today = new Date();
//   let results = []

  try {
    const eventsRef = collection(db, `events`)
    onSnapshot(eventsRef, (snapshot) => {
        let results = []
        // console.log("EVENTS ON SNAPSHOT TRIGGERED")
      if(snapshot.docs.length > 0){
        snapshot.docs.forEach((doc, idx) => {

            const event = {
              id: doc.id ?? null,
              date: doc.data().date && doc.data().date,
              dateString: doc.data().date.toDate().toDateString(),
              compareDate: doc.data().date ? doc.data().date.toDate() : '',
              description: doc.data().description ?? '',
              endTime: doc.data().endTime ?? '',
              eventCategory: doc.data().eventCategory ?? '',
              eventLat: doc.data().eventLat ?? '',
              eventLong: doc.data().eventLong ?? '',
              image: doc.data().image ?? '',
              name: doc.data().name ?? '',
              reward: doc.data().reward ?? '',
              school: doc.data().school ? doc.data().school : '',
              startTime: doc.data().startTime ?? '',
            };
    
            // Filter: filtering by upcoming, archived and all events
            if (activeFilter === 'Upcoming Events') {
              if (today <= event.compareDate) {
                results.push(event);
              }
            }
            // If event is in the past
            else if (activeFilter === 'Archived Events') {
              if (today > event.compareDate) {
                results.push(event);
              }
            } 
            //Filtering by a specific date
            else if(date) {
              if (date === event.compareDate) {
                  results.push(event);
              }
            }
            // All events
            else {
              results.push(Object.assign({}, event));
            }
        })
      }
      setEvents(results)
    })
  } catch (error) {
    console.log("ERROR SUBSCRIBING TO EVENTS COLLECTION", error);
  }
};