import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]:  {
    'login': 'INICIAR SESIÓN',
    'singUp': 'REGISTRARSE',
    'logout': 'cerrar sesión',
    'menu': "MENÚ",
    'name': 'Nombre',
    'points': 'Créditos',
    'date': 'Fecha',
    'filters': 'filtros',
    'announcement': 'anuncio',
    'announcements': 'Anuncios',
    'Events': "Eventos",
    'allEvents': 'todos los Eventos',
    'archivedEvents': 'Eventos Logrados',
    'archivedevents': 'Eventos archivados',
    'upcomingEvents': 'Próximos Eventos',
    'addNewEvent': 'Imagen del Evento',
    'addNewEventUpperCase': 'AGREGAR EVENTO',
    'searchEvents': 'Buscar Eventos',
    'searchEventsUpperCase': 'BUSCAR EVENTOS',
    'eventName': 'Nombre del Evento',
    'eventNameUpperCase': 'NOMBRE DEL EVENTO',
    'eventFormat': 'formato de Evento',
    'uploadCSV': 'CSV del Evento',
    'uploadCSVUpperCase': 'SUBIR CSV',
    'schoolName': 'Nombre de la escuela',
    'schoolNameUpperCase': 'NOMBRE DE LA ESCUELA',
    'school': 'Escuela',
    'SCHOOLCAP': 'ESCUELA',
    "download-card-title": "DESCARGA LA APLICACIÓN LISD",
    "rewards": "Recompensas",
    "view": "ver",
    "view events": "VER EVENTOS",
    "view rewards": "VER RECOMPENSAS",
    "VIEW CALENDAR": "VER CALENDARIO",
    'viewCalendarUpperCase': 'VER CALENDARIO',
    "redeem now": "canjear ahora",
    "redeem": "redimir",
    "redeemed": "Redimido",
    "credits": "creditos",
    "dashboard": "tablero de mandos",
    "events": "Eventos",
    "calendar": "calendario",
    "parents": "padres",
    "resources": "Recursos",
    "options": "opciones",
    "notes": "notas",
    'addANote': 'Agregar una nota',
    "account": "cuenta",
    "gradebook": "libro de calificaciones",
    'students': 'estudiantes',
    "unlocked": "desbloqueado",
    "locked": "bloqueado",
    "start time": "hora de inicio",
    "end time": "hora de finalización",
    "availableCredits": "créditos disponibles",
    "total available credits": "créditos disponibles",
    "available": "Dispondible",
    'redeemedCredits': 'créditos canjeados',
    'totalCreditsRedeemed': 'Créditos totales canjeados',
    'creditRequests': 'solicitudes de crédito',
    "VIEW CREDIT HISTORY": "VER HISTORIAL DE CRÉDITOS",
    "CHECK IN": "REGISTRARSE",
    "REDEEM POINTS": "CANJEAR CRÉDITOS",
    'email': 'Correo electrónico',
    'emailCap': 'CORREO ELECTRÓNICO',
    'password': 'Contraseña',
    'signIn': 'Iniciar sesión',
    'accountDescription': "No tienes una cuenta de libro de calificaciones, créala aquí",
    'changeLanguage': 'Cambiar idioma',
    'dataUpdateMidnight': 'Datos actualizados todos los días a medianoche',
    'schools': 'Escuelas',
    'eventCategories': 'Categorías de Eventos',
    'categories': 'Categorías',
    'categoriesUpperCase': 'CATEGORÍAS',
    'academic': 'Académico',
    'athletic': 'Atlético',
    'gradeLevel': 'Nivel de grado',
    'allCampus': 'Todo el campus',
    'aboutToUse': 'Estás a punto de usar ---- para este artículo',
    'eventList': 'Lista de Eventos',
    'january': 'Enero',
    'february': 'Febrero',
    'march': 'Marzo',
    'april': 'Abril',
    'may': 'Mayo',
    'june': 'Junio',
    'july': 'Julio',
    'august': 'Agosto',
    'september': 'Septiembre',
    'october': 'Octubre',
    'november': 'Noviembre',
    'december': 'Diciembre',
    'today': 'Hoy',
    'resetFilters': 'Restablecer filtros',
    'viewMore': 'Ver más',
    'calendar': 'Calendario',
    'selectDate': 'Seleccionar fecha para detalles del Evento',
    'monday': 'Lunes',
    'mMonday': 'L',
    'tuesday': 'Martes',
    'tTuesday': 'M',
    'wednesday': 'Miércoles',
    'wWednesday': 'X',
    'thursday': 'Jueves',
    'tThursday': 'J',
    'friday': 'Viernes',
    'fFriday': 'V',
    'saturday': 'Sábado',
    'sSaturday': 'S',
    'sunday': 'Domingo',
    'sSunday': 'D',
    'noteSubject': 'Asunto de la nota',
    'eventDetails': 'Detalles del Evento',
    'onlineEvent': 'Evento en línea',
    'inPersonEvent': 'Evento presencial',
    'thankYouForCheckIn': 'Gracias por registrarte en',
    'english': 'Inglés',
    'spanish': 'Español',
    'manage': 'Gestionar',
    'manageEvents': 'Gestionar Eventos',
    'manageEventsUpperCase': 'GESTIONAR EVENTOS',
    'manageRewards': 'Gestionar recompensas',
    'manageRewardsUpperCase': 'GESTIONAR RECOMPENSAS',
    'manageResources': 'Gestionar recursos',
    'manageResourcesUpperCase': 'GESTIONAR RECURSOS',
    'eventDate': 'Fecha del Evento',
    'eventDateUpperCase': 'FECHA DEL EVENTO',
    'addNewReward': 'Agregar nueva recompensa',
    'nameOfReward': 'Nombre de la Recompensa',
    'rewardName': 'Nombre de la Recompensa',
    'rewardNameUpperCase': 'NOMBRE DE LA RECOMPENSA',
    'quantity': 'Cantidad',
    'quantityUpperCase': 'CANTIDAD',
    'quantityRemainingUpperCase': 'CANTIDAD RESTANTE',
    'remainingUpperCase': 'RESTANTE',
    'pointsUpperCase': 'CRÉDITOS',
    'pointsToRedem': 'Créditos para canjear',
    'description': 'Descripción',
    'urlOfThePhoto': 'URL DE LA FOTO',
    'save': 'Guardar',
    'addNewResource': 'Agregar nuevo recurso',
    'resourceName': 'Nombre del recurso',
    'resourceNameUpperCase': 'NOMBRE DEL RECURSO',
    'resourceLink': 'Enlace del recurso',
    'resourceLinkUpperCase': "ENLACE DEL RECURSO",
    'parentCreditHistory': 'Historial de créditos de los padres',
    'totalParents': 'Total de padres',
    'filtersBy': 'Filtrar por',
    'parentName': 'Nombre del padre',
    'parentNameUpperCase': 'NOMBRE DEL PADRE',
    'totalCredits': 'Créditos totales',
    'totalCreditsUpperCase': 'CRÉDITOS TOTALES',
    'viewHistory': 'Ver historial',
    'backToParents': 'Volver a padres',
    'eventRequestCredit': "¿No ves un Evento? Solicita crédito(s)",
    'edit': 'Editar',
    'delete': 'Eliminar',
    'creditsToRedeem': 'Créditos para canjear',
    'saveChanges': 'Guardar cambios',
    'SAVE CHANGES': 'GUARDAR CAMBIOS',
    'deleteEvent': 'Eliminar Evento',
    'uploadImage': 'Subir imagen',
    'UPLOAD IMAGE': 'SUBIR IMAGEN',
    'eventDescription': 'Descripción del Evento',
    'eventStart': 'Inicio del Evento',
    'eventEnd': 'Fin del Evento',
    'streetAddress': 'Dirección',
    'eventLatitude': 'Latitud del Evento',
    'eventLongitude': 'Longitud del Evento',
    'eventCategory': 'Categoría del Evento',
    'aboutTodelete': 'Estás a punto de eliminar',
    'actionUndone': '¡Esta acción no se puede deshacer!',
    'likeToContinue': '¿Te gustaría continuar?',
    'yes': 'Sí',
    'no': 'No',
    'downloadApp': 'Descarga la aplicación LISD',
    'none': "ninguno",
    'FIRST NAME': 'PRIMER NOMBRE',
    'LAST NAME': 'APELLIDO',
    'website': 'sitio web',
    'Required': 'Requerido',
    // LOGIN ERRORS
    'user not found': 'usuario no encontrado',
    'wrong password': 'contraseña incorrecta',
    'internal error': 'error interno',
    // EVENT FORM VALIDATION ERRORS
    'SCHOOL NAME IS REQUIRED': 'SE REQUIERE EL NOMBRE DE LA ESCUELA',
    'MUST UPLOAD AN IMAGE': 'DEBE SUBIR UNA IMAGEN',
    'EVENT NAME IS REQUIRED': 'SE REQUIERE EL NOMBRE DEL EVENTO',
    'WEBSITE IS REQUIRED FOR ONLINE EVENTS': 'SE REQUIERE SITIO WEB PARA EVENTOS EN LÍNEA',
    'DESCRIPTION IS REQUIRED': 'SE REQUIERE DESCRIPCIÓN',
    'DATE IS REQUIRED': 'SE REQUIERE FECHA',
    'START TIME IS REQUIRED': 'SE REQUIERE HORA DE INICIO',
    'END TIME IS REQUIRED': 'SE REQUIERE HORA DE FINALIZACIÓN',
    'EVENT CATEGORY IS REQUIRED': 'SE REQUIERE CATEGORÍA DEL EVENTO',
    'CREDITS IS REQUIRED': 'SE REQUIERE CRÉDITOS',
    'ADDRESS IS REQUIRED': 'SE REQUIERE DIRECCIÓN',
    'LATITUDE IS REQUIRED': 'SE REQUIERE LATITUD',
    'LONGITUDE IS REQUIRED': 'SE REQUIERE LONGITUD',
    'EVENT TYPE IS REQUIRED': 'SE REQUIERE TIPO DE EVENTO',
    // DELETE MODAL
    'You are about to delete': 'Estás a punto de eliminar',
    'This action cannot be undone!': '¡Esta acción no se puede deshacer!',
    'Would you like to continue?': '¿Te gustaría continuar?',
    // REWARD FORM VALIDATION ERRORS
    'SCHOOL IS REQUIRED': 'SE REQUIERE ESCUELA',
    'COST IS REQUIRED': 'SE REQUIERE COSTO',
    'QUANTITY IS REQUIRED': 'SE REQUIERE CANTIDAD',
    'DESCRIPTION IS REQUIRED': 'SE REQUIERE DESCRIPCIÓN',
    'NAME IS REQUIRED': 'SE REQUIERE NOMBRE',
    'DELETE': 'ELIMINAR',
    'CANCEL': 'CANCELAR',
    'TOTAL': 'TOTAL',
    'AVAILABLE': 'DISPONIBLE',
    'REDEEMED': 'CANJEADO',
    'forgotpassword': 'Olvidé la contraseña',
    'resetpassword': 'Restablecer la contraseña',
    'resetdescription': "Ingresa tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.",
    'emailaddress': 'Dirección de correo electrónico',
    'submit': 'ENVIAR',
    'returntologin': 'Volver a Iniciar sesión',
    'title': 'Título',
    'addNewAnnouncementUpperCase': 'AGREGAR NUEVO ANUNCIO',
    'Event Start': 'Inicio del Evento',
    'state': 'Estado',
    'city': 'Ciudad',
    'School Name': 'Nombre de la Escuela',
    'Event Date': 'Fecha del Evento',
    'Event End': 'Fin del Evento',
    'UPLOAD SIGN UP PDF': 'SUBIR PDF DE REGISTRO',
    'Print QR Code' : 'Imprimir Código QR',
    'QR Code' : 'Código QR:',
    'UPLOAD SIGN UP PDF' : 'SUBIR PDF DE REGISTRO',
    'delete database' : 'Eliminar Base de Datos',
    // NETWORK ERROR
    "network request failed": "Fallo en la solicitud de red",
    'delete database': 'eliminar base de datos',
    'deletewarning' : ' Advertencia: Está a punto de reiniciar ParentU para un nuevo año escolar. Esta acción es permanente. Todos los padres, estudiantes, puntos y Eventos serán eliminados. ¿Desea continuar?',
    'notewarning' : ' Nota: Desactive la exportación de datos de estudiantes y padres hasta que esté listo para cargar los nuevos padres y estudiantes en el sistema.',
    'Are you sure!' : '¿Estás seguro?',
    'deletingdata' : 'Eliminando datos, por favor espera...',
    'You have successfully deleted below collections:': "Has eliminado correctamente las siguientes colecciones:",
    'checkinCountUpperCase' : "CANTIDAD DE CHECK-IN",
    'descriptionUpperCase' : 'DESCRIPCIÓN',
    'dateUpperCase' : "FECHA",
    'titleUpperCase' : "TÍTULO",
    'eventImage': "Imagen del Evento:",
    'eventPDF' : "PDF del Evento:",
    'EXPORT EVENT': "EVENTO DE EXPORTACIÓN",
    'DELETE EVENT' : "ELIMINAR EVENTO",
    'Paper SignUp Sheet' : "Hoja de registro en papel",
    'Remove PDF' : 'Eliminar PDF',
    'Remove Image' : 'Quita la Imagen',
    'PDF FILE IS REQUIRED': 'SE REQUIERE UN ARCHIVO PDF',
    'IMAGE FILE IS REQUIRED': 'SE REQUIERE ARCHIVO DE IMAGEN',
    "descriptionRequired" : 'Se requiere descripción',
    'Credit History': 'Historial de crédito',
    'General': 'General',
    'Credits are required': 'Se requieren créditos',
    "ViewRequest":"Ver solicitud",
    "Approve": "Aprobar",
    "Cancel": "Cancelar",
    "veiwHistory": "VER HISTORIAL",
    "Parents Check In Count": "Recuento de Registros de Padres",
    "Parent Name": "Nombre de Pariente",
    "Parent Email": "Correo Electronico de Pariente",
    "Select All": "Seleccionar todo",
    //reward-form
    'Edit Reward': 'Editar Recompensa',
    'Parents Redeemed' : 'Padres Redimidos',

    "Required" : "Requerido",
    'scanQRCode' : "Escanee este código QR para registrarse y recibir recompensas"
  }
}
