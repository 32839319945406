import React, { useContext, useState } from 'react';
import { AppContext } from '~context';
import { useTheme } from '@mui/material/styles';
import { Divider, Typography, Stack } from '@mui/material';
import translate from '~translate';

function Filter() {
  const { activeFilter, setActiveFilter } = useContext(AppContext);
  const theme = useTheme();

  const makeActive = (name) => {
    setActiveFilter(name);
    // console.log(activeFilter);
  };

  const styles = {
    active: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 20,
      paddingLeft: 1,
      fontFamily: 'Poppins',
      textTransform: 'capitalize',
      cursor: 'pointer',
    },
    inactive: {
      color: theme.palette.secondary.main,
      fontSize: 20,
      paddingLeft: 1,
      fontFamily: 'Poppins',
      textTransform: 'capitalize',
      cursor: 'pointer',
    }
  }


  return (
    <>
      <Stack
        spacing={4}
        direction='row'
        divider={
          <Divider
            orientation='vertical'
            flexItem
            sx={{ border: '1px solid #3B3C86', height: '25px' }}
          />
        }
      >
        <Typography
      
          variant='h5'
          onClick={() => makeActive('All Events')}
          sx={() =>
            activeFilter === 'All Events' ? styles.active : styles.inactive
          }
        >
          {translate("allEvents")}
        </Typography>
        <Typography
          variant='h5'
          style={{ cursor: 'pointer' }}
          onClick={() => makeActive('Archived Events')}
          sx={() =>
            activeFilter === 'Archived Events'
              ? styles.active : styles.inactive
          }
        >
          {translate("archivedEvents")}
        </Typography>
        <Typography
          variant='h5'
          style={{ cursor: 'pointer' }}
          onClick={() => makeActive('Upcoming Events')}
          sx={() =>
            activeFilter === 'Upcoming Events'
              ? styles.active : styles.inactive
          }
        >
          {translate("upcomingEvents")}
        </Typography>
      </Stack>
    </>
  );
}

export default Filter;
