export const getStudentsOnEvent = (event, students) => {
  const studentsOnEvent = []
  //console.log("Getting event students")
  //console.log("Event: ", event)
  //console.log("Event students: ", students)


  students && students.forEach((student) => {
      if(student.studentSchool === event.data().school || event.data().school === 'Select All') {
          studentsOnEvent.push(student)
          //console.log("Event's students: ", student)
      }
  })
  return studentsOnEvent;
};