import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';
import { ThemeProvider } from '@mui/material/styles';
import { CookiesProvider } from "react-cookie";
import AppContextProvider from '~context';
import './App.css';

import { theme } from "./utils/theme";


function App() {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <BrowserRouter>
              <Router />
          </BrowserRouter>
        </AppContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
