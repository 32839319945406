import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { getCount } from '~functions/queries';
import Skeleton from '@mui/material/Skeleton';
import translate from '~translate';
import { useTheme } from '@mui/material/styles'; // Import useTheme from MUI
import useStyles from './styles'; // Adjust the import path as needed


const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme(); // Use useTheme to access MUI theme
  const [eventsCount, setEventsCount] = useState('');
  const [rewardsCount, setRewardsCount] = useState('');
  const [resourcesCount, setResourcesCount] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        const countE = await getCount('events');
        const countRewards = await getCount('rewards');
        const countResources = await getCount('resources');

        setEventsCount(countE);
        setRewardsCount(countRewards);
        setResourcesCount(countResources);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getData();
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.cardsContainer}>
        <Card count={eventsCount} type="events" link="/home/events" />
        <Card count={rewardsCount} type="rewards" link="/home/rewards" />
        <Card count={resourcesCount} type="resources" link="/home/resources" />
      </div>
    </div>
  );
};

const Card = ({ count = 0, link, type = 'none' }) => {
  const classes = useStyles();

  if (count) {
    return (
      <div className={classes.card}>
        <p className={classes.cardTitle}>{translate(type)}</p>
        <p className={classes.count}>{count}</p>
        <Link to={link} className={classes.link}>
          <p className={classes.link}>{translate('manage')} {translate(type)}</p>
        </Link>
      </div>
    );
  } else {
    return (
      <div className={`${classes.card} ${classes.whiteBackground}`}>
        <Skeleton variant="rectangular" className={classes.skeleton} width={300} height={300} />
      </div>
    );
  }
};

export default Dashboard;
