const addEventsToCalendar = (events) => {
    // console.log("EVENTS", events)
      const formatMarkDate = (date) => {
        // console.log(date);
        return `${(date.getMonth() + 1).toString()}-${date
          .getDate()
          .toString()}-${date.getFullYear()}`;
      };

      let markList = [];
      events.forEach((event) => {
        markList.push(formatMarkDate(new Date(event.date.seconds * 1000)))
      })
      // console.log("CALENDAR MARKS: ", markList)
      return markList
};

export {
    addEventsToCalendar,
};