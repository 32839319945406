import './table.css';
import translate from '~translate';

const columns = [
  
  {
    field: 'name',
    headerName: translate('name'),
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: true,
  },
  {
    field: 'cost',
    headerName: translate('points'),
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
  },
  {
    field: 'date',
    headerName: translate('date'),
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
  },
];
const redeemedColumns = [
  
  {
    field: 'name',
    headerName: translate('name'),
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: true,
  },
  {
    field: 'school',
    headerName: translate('school'),
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: true,
  },

  {
    field: 'cost',
    headerName: translate('points'),
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
  },
  {
    field: 'date',
    headerName: translate('date'),
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: true,
  },
];

export { columns, redeemedColumns };
