import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: "gs://lisd-59506.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// Connect to Firebase emulators for local development
// if (window.location.hostname === 'localhost') {
  
//   // Firestore Emulator
//   connectFirestoreEmulator(db, 'localhost', 8080);
  
//   // Authentication Emulator
//   connectAuthEmulator(auth, 'http://localhost:9099');
  
//   // Functions Emulator
//   connectFunctionsEmulator(functions, 'localhost', 5001);
  
//   // // Storage Emulator
//   connectStorageEmulator(storage, 'localhost', 9199);
// }

export { auth, db, storage, functions };
export const placesKey = 'AIzaSyD4fo-IYKa3bRUTwFotYVA41zLE_ByJuKM';
export default app;
