import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLocation, useNavigate } from "react-router-dom";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@mui/material/styles';
import { AppContext } from "~context";
import { makeStyles } from '@mui/styles';
import translate from '../../i18n/translate';
import { getAuth, signOut } from "firebase/auth";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',  
    },
    logoutWrapper: {
        minWidth: 120,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "flex-end",
        paddingLeft: 15,
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.8
        }
    }
}));

export default function ButtonAppBar({ setLanguageCookie }) {
    const { setLocale, navList, language, setLanguage } = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const auth = getAuth();
    const navigate = useNavigate();

    const checkActiveLink = () => {
        const currentPage = navList.filter(item => item.link === location.pathname);
        return currentPage.length > 0 ? translate(currentPage[0].name) : location.state ? location.state.name : '';
    }

    const handleChange = (event) => {
        setLanguage(event.target.value);
        setLocale(event.target.value);
        setLanguageCookie(event.target.value);
    };

    const onSignout = () => {
        signOut(auth)
            .then(() => {
                navigate("/");
            })
            .catch((error) => {
                console.error("Error signing out: ", error);
            });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                color='secondary'
                elevation={0}
            >
                <Toolbar sx={{ py: 3 }}>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, textAlign: 'left', color: theme.palette.neutral.main, fontFamily: 'Poppins', textTransform: "capitalize" }}>
                        {checkActiveLink()}
                    </Typography>
                    <Box className={classes.container}>
                        <FormControl fullWidth variant="standard">
                            <Select
                                id="language-select"
                                value={language}
                                onChange={handleChange}
                                disableUnderline
                                style={{ color: theme.palette.neutral.main, fontWeight: 600, fontSize: '0.9rem', fontFamily: 'Poppins' }}
                                sx={{ color: "#fff", "& .MuiSvgIcon-root": { color: "white", } }}
                            >
                                <MenuItem value={'en-us'}>{translate('english')}</MenuItem>
                                <MenuItem value={'es-es'}>{translate('spanish')}</MenuItem>
                            </Select>
                        </FormControl>
                        <div className={classes.logoutWrapper} onClick={onSignout}>
                            <PersonIcon color="neutral" fontSize='large' />
                            <Typography variant="body2" sx={{ paddingLeft: 1, textAlign: 'left', color: theme.palette.neutral.main, fontFamily: 'Poppins', fontWeight: 600, textTransform: 'capitalize' }}>
                                {translate('logout')}
                            </Typography>
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
