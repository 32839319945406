import React from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    dividerLine: {
      width: '80%',
      height: 1,
      backgroundColor: theme.palette.neutral.secondary
    },
    lineContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 5,
        paddingBottom: 5
    }
  }));

export const DividerLine = () => {
    const classes = useStyles();

  return (
    <div className={classes.lineContainer}>
        <div className={classes.dividerLine}></div>
    </div>
  );
};
