import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { collection, getDocs } from 'firebase/firestore';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { AppContext } from '~context';
import ActionBar from '../../components/rewards/action-bar';
import { db } from '../../firebase';
const TableComponent = lazy(() =>
  import('../../components/rewards/reward-table')
);

const RewardForm = lazy(() =>
  import('../../components/rewards/reward-form')
);


const useStyles = makeStyles(() => ({
  actionBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '70px',
    minWidth: '100%',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Rewards = () => {
  const { rewardFormOpen } = useContext(AppContext);
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  let tableRows = [];

  const getRewards = async () => {
    try {
      const queryRef = collection(db, 'rewards');
      const rewardsSnapshot = await getDocs(queryRef);
      rewardsSnapshot.forEach((doc) => {
        const reward = {
          id: doc.id ?? null,
          name: doc.data().name ?? '',
          description: doc.data().description ?? '',
          image: doc.data().image ?? '',
          school: doc.data().school ?? '',
          vendor: doc.data().vendor ?? '',
          cost: parseInt(doc.data().cost) ?? 0,
          quantity: parseInt(doc.data().quantity) ?? 0,
          quantityLeft: doc.data().quantityLeft ? parseInt(doc.data().quantityLeft) : parseInt(doc.data().quantity),
        };
        tableRows.push(reward);
        }
      );
      setRows(tableRows);
    } catch (error) {
      console.log('ERROR!!', error);
    }
  };

  const updateRewards = () => {
    setRows([]);
    getRewards();
  };

  useEffect(() => {
    updateRewards();
  }, []);

  return (
    <>
    {rewardFormOpen ? (
        <Suspense fallback={<div>Loading..........</div>}>
          <RewardForm getRewards={updateRewards} />
        </Suspense>
      ) : (
      <Grid container rowSpacing={0}>
        <Grid item xs={12} className={classes.actionBarContainer}>
          <ActionBar getRewards={updateRewards}/>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ width: '100%', minHeight: '500px', overflow: 'hidden' }}
          className={classes.tableContainer}
        >
          <Suspense fallback={<div>Loading..........</div>}>
            <TableComponent data={rows} />
          </Suspense>
        </Grid>
      </Grid>
      )}
    </>
  );
};

export default Rewards;
