import React, { useContext } from 'react';
import { auth } from '../../../firebase';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../utils/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Paper, Button } from '@mui/material';
import { getAuth, signOut } from 'firebase/auth';
import translate from '../../../i18n/translate';
import { AppContext } from '~context';

// import logo from '../../../res/lubbock-isd.png';
import logo from '../../../res/logo.png';
import { textTransform } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  navContainer: {
    height: '100%',
    [theme.breakpoints.down('xl')]: {
      height: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 25,
    height: '100vh',
    width: '15vw',
    [theme.breakpoints.down('xl')]: {
      width: '20vw',
    },
  },
  menuTitleContainer: {
    width: '100%',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  menuButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 60,
    paddingBottom: 40,
  },
  navFooter: {
    width: '100%',
  },
  logo: {
    width: 100,
  },
}));

export const DesktopSideNav = () => {
  const { navList } = useContext(AppContext);

  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const showOnDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  const onSignout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        //   setMobileMenuOpen(false);
        navigate('/');
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const navigateTo = (link) => {
    // setMobileMenuOpen(false);
    setTimeout(() => {
      navigate(link);
    }, 250);
  };

  const checkActiveLink = (item) => {
    // console.log(location.pathname);
    if (item.link === location.pathname) {
      return {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: 18,
        paddingLeft: 1,
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
      };
    } else {
      return {
        color: theme.palette.secondary.main,
        paddingLeft: 1,
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
      };
    }
  };

  return (
    <Paper className={classes.navContainer} elevation={4}>
      <div className={classes.drawer}>
        <Box
          sx={showOnDesktop ? { width: '15vw' } : { width: '20vw' }}
          role='presentation'
        >
          <div className={classes.menuTitleContainer}>
            <img style={{ width: '80%' }} src={logo} alt='fireSpot' />
          </div>
          <List>
            {navList.map((text, index) => (
              <ListItem
                key={text.name}
                onClick={() => navigateTo(text.link)}
              >
                <ListItemButton>
                  <ListItemText
                    disableTypography={true}
                    primary={translate(text.name)}
                    sx={checkActiveLink(text)}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </div>
    </Paper>
  );
};
