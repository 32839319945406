import * as React from 'react';
import {useContext } from 'react';
import {Box, Typography, Stack} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import translate from '~translate';
import './style.css'
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { AppContext } from '~context';
const SearchBar = (props) => {
  const { parents, setParents,
    lastVisible,
    setLastVisible,
    subscribeParents, loading, setLoading } = useContext(AppContext);
  React.useEffect(() => {
    subscribeParents(parents, setParents, lastVisible, setLastVisible, setLoading);
  }, [parents]);
    
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
        <Stack 
            direction='row'
            justifyContent={'space-between'}
            spacing={2}
            style={{ paddingTop: '25px', display: 'flex' }}
        >
          <div>
          <GridToolbarContainer>
            <GridToolbarExport csvOptions={{
              fileName: 'parents',
              delimiter: ',',
              fields: ['firstName', 'lastName', 'parentsEmail', 'schools', 'totalCredits', 'creditsRedeemed', 'availableCredits'],
            }} />
          </GridToolbarContainer>
          </div>
         <div>
         <Typography
            // variant='h5'
            sx={{
              color: '#3B3C86',
              font: 'normal normal normal 30px/46px Poppins',
              padding: '10px',
            }}
            className='capitalize'
          >
            {translate('totalParents')}: <Typography sx={{
              color: 'red',
              font: 'normal normal normal 30px/46px Poppins',
              padding: '10px',
            }}
            variant='caption'
            >{loading ? 'Loading ...' : props.data?.length}</Typography>
          </Typography>
          
         </div>
         {/*<Button
                variant='outlined'
                type='submit'
                onClick={() => {
                  subscribeParents(parents, setParents, lastVisible, setLastVisible);
                }}
                sx={{
                  height: 40,
                  border: '1px solid #3B3C86 ',
                  opacity: 1,
                  fontSize: '10px',
                  fontWeight: 'bold',
                  color: 'red',
                  backgroundColor: 'transparent',
                  borderRadius: 10,
                  alignSelf: 'center',
                }}
              >
                Next
              </Button>*/}
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(0.5, 0.5, 0.5),
          '& .MuiSvgIcon-root': {
            mr: 0,
            ml: 0.5
          },
          '& .MuiInput-underline:before': {
            border: 1,
            padding: 1,
            width: '100%',
            borderColor: 'divider',
          },
        }}
      />
      </Stack>
    </Box>
  );
};

export default SearchBar;
