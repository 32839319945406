import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc, query } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '~context';
import { db, functions } from '../../firebase';
import translate from '../../i18n/translate';
import mainLogo from '../../res/logo.png';
import styles from './styles';
const useStyles = makeStyles(styles);

export const Login = () => {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('')

  const classes = useStyles();
  //states from context api
  const { email, setEmail, password, setPassword, setLocale } = useContext(
    AppContext
  );

  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(passwordError)
  },[passwordError])

  
  const configureErrorMessage = (error) => {
    const message = parseError(error);

    // console.log('error message', message);

    if (message.includes('password')) {
      setPasswordError(message);
    }
    else {
        setEmailError(message);
    };
  }

  const parseError = (error) => {
    const errorSubstring = error.substring(error.indexOf('/') + 1);
    return errorSubstring.replace(/-/g, ' ');
  };

    const onSignin = async (e) => {
      e.preventDefault();
      setEmailError('');
      setPasswordError('');
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const adminsRef = query(doc(db, `admins/admins`));
          const adminsSnapshot = await getDoc(adminsRef);
          // console.log(adminsSnapshot.data().emails);
          const adminEmails = adminsSnapshot.data().emails;
          if (adminEmails.includes(email)) {
            const addAdminRole = httpsCallable(functions, 'addAdminRole')
            await addAdminRole({email: email})
              .then((result) => {
                console.log("ADMIN RES", result);
              })
              .catch(err => {
                alert(err);
                
              })
            navigate('home/dashboard');
            getAuth().currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                // console.log("ID TOKEN RESULT", idTokenResult.claims);
              })
              .catch(err => {
              alert(err);
            })
        } else {
          alert('You are not authorized to access this site. Please contact LISD administration if this is an error.');
          signOut();
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        configureErrorMessage(errorCode);
      });
  };

  return (
    <div className={classes.mainContainer}>
      <Button onClick={() => setLocale('en-us')}>{translate('english')}</Button>
      <Button onClick={() => setLocale('es-es')}>{translate('spanish')}</Button>
      <img src={mainLogo} className={classes.logo} alt='fireSpot' />
      <Box
        onSubmit={onSignin}
        className={classes.formContainer}
        sx={{
          '& > :not(style)': { m: 1, width: '30ch' },
        }}
        noValidate
        autoComplete='off'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='h6'
          gutterBottom
          component='div'
          color='secondary'
          className={classes.type}
        >
          {translate('login')}
        </Typography>
        <form>
          <div className={classes.inputButtonContainer}>
            <div className={classes.textFieldContainer}>
              <TextField
                fullWidth
                id='outlined-basic'
                label='Email'
                variant='outlined'
                color='secondary'
                onChange={(e) => {
                  setEmail(e.target.value);
                  // console.log('testing', email);
                }}
                value={email}
              />
              {emailError && <p style={{margin: 0, color: "red", textTransform: "uppercase"}}>{translate(emailError)}</p>}
              <TextField
                fullWidth
                id='outlined-basic'
                label={translate('password')}
                type='password'
                variant='outlined'
                color='secondary'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              {passwordError && <p style={{margin: 0, color: "red", textTransform: "uppercase"}}>{translate(passwordError)}</p>}
            </div>
            <Button style={{marginBottom: 20}} variant='contained' type="submit" onClick={(e) => onSignin(e)}>
              {translate('login')}
            </Button>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
              <Link href="/forgot-password" underline='none' color='secondary'>{translate('forgotpassword')}?</Link>
            </div>
          </div>
        </form>
      </Box>
      <p>Version 1.5</p>
    </div>
  );
};
