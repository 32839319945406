import { db } from "../../firebase";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { dateToLocaleTimeString, timeToDateString } from "../../functions";

export const updateParentCredits = async (parentId, event, eventStudents) => {
  const parentDocRef = doc(db, "parents", parentId);
  const creditRef = doc(parentDocRef, "credits-history", event.id);


  //console.log(eventStudents);
  // Check if event fields are properly defined
  const eventReward = parseInt(event.data().reward);
  const eventName = event.data().name;
  const eventStudentsList = eventStudents;

  if (!eventReward || !eventName || !eventStudentsList) {
    console.error("Event fields are not properly defined:", { eventReward, eventName, eventStudentsList });
    return alert("Event fields are not properly defined.");
  }
  const dateString = timeToDateString(event.data().date);

  const credit = {
    date: dateString,
    credits: eventReward,
    name: eventName,
    students: eventStudentsList,
  };

  try {
    const parent = await getDoc(parentDocRef);
   // console.log("Parent document: ", parent);
    const totalCredits = (parent.exists() && parent.data().totalCredits) ?? 0;
   // console.log("Total credits: ", totalCredits);
   // console.log("Event reward: ", event.reward);
    const creditsNow = parseInt(totalCredits) + eventReward;
   // console.log("Credits now: ", creditsNow);
    await setDoc(parentDocRef, { totalCredits: creditsNow }, { merge: true });
    await setDoc(creditRef, credit);
   // console.log("Parent credits updated successfully");
  } catch (error) {
    console.error("Error updating parent credits: ", error);
  }
};
